import React, { Component, ErrorInfo, ReactNode } from 'react';
import { Box, Typography } from "@mui/material";

interface ErrorBoundaryProps {
    children: ReactNode;
    resetKeys: Array<any>; // Define resetKeys as an array of any type
}

interface ErrorBoundaryState {
    hasError: boolean;
    errorMessage?: string;
    errorStack?: string;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error): ErrorBoundaryState {
        return { hasError: true, errorMessage: error.message };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error("Error caught by Error Boundary: ", error, errorInfo);
        this.setState({ errorStack: errorInfo.componentStack ?? undefined });
    }

    componentDidUpdate(prevProps: ErrorBoundaryProps) {
        // Check if resetKeys have changed
        if (this.state.hasError && !this.areResetKeysSame(this.props.resetKeys, prevProps.resetKeys)) {
            // Reset the error state if resetKeys have changed
            this.resetErrorState();
        }
    }

    resetErrorState() {
        this.setState({ hasError: false, errorMessage: undefined, errorStack: undefined });
    }

    areResetKeysSame(newKeys: Array<any>, prevKeys: Array<any>): boolean {
        // Simple comparison of resetKeys arrays (assuming flat array of primitive values)
        return newKeys.length === prevKeys.length && newKeys.every((key, index) => key === prevKeys[index]);
    }

    render() {
        if (this.state.hasError) {
            return (
                <Box style={{ width: '100%', display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                    <Typography variant="h6" style={{marginTop: '20px', color: 'red'}}>Something went wrong.</Typography>
                    <Typography variant="body1" style={{marginTop: '10px'}}>{this.state.errorMessage}</Typography>
                    <Typography variant="caption" style={{marginTop: '10px', color: 'gray'}}>{this.state.errorStack}</Typography>
                </Box>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;