import PostMolecule from "../Core/PostMolecule";
import AuditInfo from "../Core/AuditInfo";
import Atom from "../Core/Atom";
import ContentStatus from "../Core/ContentStatus";
import AtomDeserializer from "../Core/AtomDeserializer";

class TopicMolecule extends PostMolecule {
    public status: ContentStatus;
    constructor(id: string | null, etag: string | null, atoms: Atom[], title: string, status?: ContentStatus, audit?: AuditInfo) {
        super(id, etag, atoms, title, audit);
        this.status = status ?? ContentStatus.WIP;
    }

    toJSON() {
        return { ...this, type: 'Topic' };
    }

    static fromJSON(json: any): TopicMolecule {
        const atoms = AtomDeserializer.deserializeArray(json._atoms);
        const audit = json.audit === undefined ? undefined : JSON.parse(json.audit);
        return new TopicMolecule(json.id, json.etag, atoms, json.title, json.status, audit);
    }
}

export default TopicMolecule;