import ContentAtom from "./ContentAtom";
import BlockDeserializer from "./BlockDeserializer";

class MarkdownAtom extends ContentAtom {

    toJSON() {
        return { ...this, type: 'Markdown' };
    }

    override getWordCount(): number {
        // Remove markdown-specific characters
        const plainText = this.getContent()
            .replace(/[`*_{}[\]()#+.!-]/g, ' ')  // Replace markdown syntax characters with space
            .replace(/!\[.*?]\(.*?\)/g, ' ')  // Remove images
            .replace(/\[.*?]\(.*?\)/g, ' ')  // Remove links
            .replace(/#+/g, ' ')  // Remove heading markers
            .replace(/-+/g, ' ')  // Remove list item markers
            .replace(/\s+/g, ' ')  // Replace multiple spaces with a single space
            .trim();  // Trim leading and trailing spaces

        const words = plainText.split(/\s+/).filter(word => word.length > 0);
        return words.length;
    }

    static fromJSON(json: any) {
        return new MarkdownAtom(json.id, json.etag, BlockDeserializer.deserialize(json.block));
    }
}

export default MarkdownAtom;