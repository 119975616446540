import {ShowRef} from "./VerseComponent";


export enum LayoutStyle {
    Reader,
    ReaderMelded,
    ReaderFramed,
    Search,
    Topic,
    Explore,
    Editing,
}

class VerseLayout {
    canClose: boolean;
    showTitle: boolean;
    showTopicTitle: boolean;
    showCard: boolean;
    showRef: ShowRef;
    expandTopics: boolean;
    expandNotes: boolean;
    canExpandContext: boolean;

    constructor(
        canClose: boolean, showTitle: boolean, showTopicTitle: boolean, showCard: boolean, showRef: ShowRef,
        expandTopics: boolean, expandNotes: boolean, canExpandContext: boolean) {
        this.canClose = canClose;
        this.showTitle = showTitle;
        this.showTopicTitle = showTopicTitle;
        this.showCard = showCard;
        this.showRef = showRef;
        this.expandTopics = expandTopics;
        this.expandNotes = expandNotes;
        this.canExpandContext = canExpandContext;
    }

    public static fromStyle(layoutStyle: LayoutStyle, numVerses?: number): VerseLayout {
        numVerses = numVerses ?? 1;
        if (layoutStyle === LayoutStyle.Reader && numVerses > 1) {
            layoutStyle = LayoutStyle.ReaderMelded;
        }
        switch (layoutStyle) {
            case LayoutStyle.Reader:
                return new VerseLayout(true,true, false, false, ShowRef.LEFT,
                    false, false, false);
            case LayoutStyle.ReaderMelded:
                return new VerseLayout(true,true, false, true, ShowRef.FULL,
                    false, false, false);
            case LayoutStyle.ReaderFramed:
                return new VerseLayout(false,true, false, true, ShowRef.FULL,
                    false, true, true);
            case LayoutStyle.Search:
                return new VerseLayout(true,false, false, true, ShowRef.FULL,
                    false, false, true);
            case LayoutStyle.Topic:
                return new VerseLayout(false,false, true,true, ShowRef.FULL,
                    false, true, true);
            case LayoutStyle.Explore:
                return new VerseLayout(true, true, false, true, ShowRef.FULL,
                    true, true, false);
            case LayoutStyle.Editing:
                return new VerseLayout(false, false, false, true, ShowRef.FULL,
                    false, false, true);
        }
    }
}

export default VerseLayout;