import React, {useState} from 'react';
import {Typography} from '@mui/material';
import {useParams, useLocation} from "react-router-dom";
import {APP_NAME} from "../constants";
import './SearchPage.css';
import StyledContentHeader from "../styles/StyledContentHeader";
import {useBible} from "../contexts/BibleContext";
import {StrongsEntry} from "../model/Biblical/StrongsEntry";

type StrongsParams = {
    strongs: string;
};

const StrongsPage: React.FC = () => {
    const { strongs } = useParams<StrongsParams>();
    const [entry, setEntry] = React.useState<StrongsEntry>();
    const [error, setError] = useState<Error | undefined>(undefined);
    const location = useLocation();
    const bible = useBible();
    if (error !== undefined) {
        throw error;
    }

    React.useEffect(() => {
        async function fetchVerses() {
            document.title = `${APP_NAME} - ${strongs}`;
            if (strongs === undefined) {
                throw new Error("No results found because the strongs number was not provided.")
            }
            return await bible.getStrongsEntry(strongs);
        }

        fetchVerses()
            .then(verses => {
                setError(undefined);
                setEntry(verses);
            })
            .catch(error => setError(error));
    }, [strongs, bible, location.search]);

    return (
        <>
            <StyledContentHeader style={{
                display: "flex",
                justifyContent: "center"
            }}>
                <Typography variant="h5">
                    {strongs}
                </Typography>
                {entry && (
                    <div>
                        <p><strong>Definition:</strong> {entry.strongsDef}</p>
                        <p><strong>Derivation:</strong> {entry.derivation}</p>
                        <p><strong>Transliteration:</strong> {entry.translit}</p>
                        <p><strong>Lemma:</strong> {entry.lemma}</p>
                        <p><strong>KJV Definition:</strong> {entry.kjvDef}</p>
                    </div>
                )}
            </StyledContentHeader>
        </>
    );
}

export default StrongsPage;