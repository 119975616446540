import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {ServerError} from "../core/errors/ServerError";

interface Degradation {
    type: 'Internet' | 'Login';
    feature: string;
    timestamp: number;
}

interface AppStateContextType {
    isLoggedIn: boolean;
    isOnline: boolean;
    checkLogin: () => void;
    checkInternet: () => void;
    loginError?: ServerError;
    degradations: Degradation[];
    reportDegradation: (type: 'Internet' | 'Login', feature: string) => void;
}

const AppStateContext = createContext<AppStateContextType | undefined>(undefined);

export const AppStateProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);
    const [loginError, setLoginError] = useState<ServerError>();
    const [degradations, setDegradations] = useState<Degradation[]>([]);
    const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();

    const checkLogin = async () => {
        try {
            await getAccessTokenSilently();
            setIsLoggedIn(true);
            setLoginError(undefined);
        } catch (error) {
            setIsLoggedIn(false);
            setLoginError(new ServerError("Error getting access token.", error));
        }
    };

    const checkInternet = () => {
        setIsOnline(navigator.onLine);
    };

    useEffect(() => {
        window.addEventListener('online', checkInternet);
        window.addEventListener('offline', checkInternet);

        // Clean up
        return () => {
            window.removeEventListener('online', checkInternet);
            window.removeEventListener('offline', checkInternet);
        };
    }, []);

    useEffect(() => {
        if (!isLoading) { // Ensure Auth0 is not in a loading state
            if (isAuthenticated) {
                getAccessTokenSilently().then(() => {
                    // If the token is received, the user is definitely logged in.
                }).catch(error => {
                    setLoginError(new ServerError("Error getting access token.", error));
                });
            }
        }
    }, [isAuthenticated, isLoading, getAccessTokenSilently]);

    const reportDegradation = (type: 'Internet' | 'Login', feature: string) => {
        setDegradations(current => [...current, { type, feature, timestamp: Date.now() }]);
    };

    // Cleanup old degradations every 30 seconds and remove those older than 5 minutes
    useEffect(() => {
        const interval = setInterval(() => {
            setDegradations(current =>
                current.filter(degradation => Date.now() - degradation.timestamp < 5 * 60 * 1000)
            );
        }, 30000);

        return () => clearInterval(interval);
    }, []);

    return (
        <AppStateContext.Provider value={{ isLoggedIn, isOnline, checkLogin, checkInternet, loginError, degradations, reportDegradation  }}>
            {children}
        </AppStateContext.Provider>
    );
};

export const useAppState = () => {
    const context = useContext(AppStateContext);
    if (context === undefined) {
        throw new Error('useAppState must be used within an AppStateProvider');
    }
    return context;
};
