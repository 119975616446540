import Atom from "../Core/Atom";

class StrongsAtom extends Atom {
    public strongs: string;
    constructor(id: string | null, etag: string | null, strongs: string) {
        super(id, etag);
        this.strongs = strongs;
    }

    override getName(): string {
        return `Strongs ${this.strongs}`;
    }

    toJSON() {
        return {
            ...super.toJSON(),
            strongs: this.strongs,
            type: 'Strongs'
        };
    }

    static fromJSON(json: any) {
        return new StrongsAtom(json.id, json.etag, json.strongs);
    }
}

export default StrongsAtom;