import React from "react";
import {SessionContext} from "./SessionContext";
import SessionManager from "./SessionManager";

interface SessionProviderProps {
    children: React.ReactNode;
}

export const SessionProvider = ({ children }: SessionProviderProps) => {
    const eventManager = new SessionManager();

    return (
        <SessionContext.Provider value={eventManager}>
            {children}
        </SessionContext.Provider>
    );
};
