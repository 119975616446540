import Atom from "./Atom";
import {SemanticRef} from "../SemWeb/SemanticRef";
import Molecule from "./Molecule";

export class RelatedAtom extends Atom {
    public refs: SemanticRef[];
    public label: string|null;
    constructor(id: string|null, etag: string|null, label: string|null, refs: SemanticRef[]) {
        super(id, etag);
        this.refs = refs;
        this.label = label;
    }

    public static addToExisting(molecule: Molecule, label: string, ref: SemanticRef): RelatedAtom {
        let existing = molecule.getAtomsOf(RelatedAtom);
        if (label !== undefined) existing = existing.filter(a => a.label === label);
        if (existing.length === 0) {
            return molecule.push(new RelatedAtom(null, null, label, [ref])) as RelatedAtom;
        }
        existing[0].refs.push(ref);
        return existing[0];
    }

    toJSON() {
        return { ...this, type: 'Related' };
    }

    static fromJSON(json: any): RelatedAtom {
        return new RelatedAtom(json.id, json.etag, json.label, json.refs);
    }
}