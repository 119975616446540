// MenuContext.tsx
import React, { createContext, useContext, useState } from 'react';

interface MenuProviderProps {
    children: React.ReactNode;
}

const MenuContext = createContext({
    menuItems: [] as React.ReactNode[],
    setMenuItems: (items: React.ReactNode[]) => {},
});

export const useMenu = () => useContext(MenuContext);

export const MenuProvider = ({ children }: MenuProviderProps) => {
    const [menuItems, setMenuItems] = useState<React.ReactNode[]>([]);
    return (
        <MenuContext.Provider value={{ menuItems, setMenuItems }}>
            {children}
        </MenuContext.Provider>
    );
};