import styled from '@emotion/styled';
import {TextField} from "@mui/material";

const StyledAutocompleteTextField = styled(TextField) ({
    backgroundColor: '#343747',
    borderRadius: '1rem',
    margin: 0,
    padding: 0,
});

export default StyledAutocompleteTextField;