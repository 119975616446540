import {StrongsEntry} from "../model/Biblical/StrongsEntry";
import ApiClient from "../core/ApiClient";

class StrongsService {
    private readonly getAccessToken?: (required: boolean) => Promise<string | undefined>

    constructor(getAccessToken?: (required: boolean) => Promise<string | undefined>) {
        this.getAccessToken = getAccessToken;
    }

    public async getEntry(strongs: string): Promise<StrongsEntry> {
        const client = new ApiClient((r) => Promise.resolve(undefined));
        return await client.getSingle<StrongsEntry>(
            `https://rtar.org/.netlify/functions/strongs?no=${strongs}`,
            StrongsEntry.fromJSON);
    }
}

export default StrongsService;