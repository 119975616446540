import Atom from "./Atom";

class Molecule extends Atom {
    private _atoms: Atom[]
    constructor(id: string | null, etag: string | null, atoms: Atom[] = []) {
        super(id, etag);
        this._atoms = atoms;
    }

    public get atoms(): Atom[] {
        return this._atoms ?? [];
    }

    public hasAtom(id: string | null): boolean {
        if (id === undefined) return false;
        return this.atoms.some(a => a.id === id);
    }

    public getWordCount(): number {
        return this._atoms.map(x => x.getWordCount()).reduce((p, c) => p + c, 0);
    }

    public getAtomsOf<T extends Atom>(type: { new(...args: any[]): T }): T[] {
        return this._atoms.filter(atom => atom instanceof type) as T[];
    }

    public push(atom: Atom): Atom {
        this._atoms.push(atom);
        return atom;
    }

    public insertAfter(after: Atom, newAtom: Atom): Atom {
        const index = this._atoms.indexOf(after);
        if (index !== -1) {
            this._atoms.splice(index + 1, 0, newAtom);
        } else {
            throw new Error('The atom after which to insert was not found.');
        }

        return newAtom;
    }

    public pushRange(atoms?: Atom[]) {
        atoms?.forEach(a => this.push(a));
    }

    public remove(atom: Atom): Atom[] {
        return this._atoms = this._atoms
            .filter(s => s.id !== atom.id);
    }

    public move(sourceIndex: number, destinationIndex: number): Atom[] {
        const items = Array.from(this._atoms);
        const [reorderedItem] = items.splice(sourceIndex, 1);
        items.splice(destinationIndex, 0, reorderedItem);
        return this._atoms = items;
    }

    public splitAt(atom: Atom): Molecule {
        const index = this._atoms.indexOf(atom);
        if (index === -1) {
            throw new Error('The atom at which to split the molecule was not found.');
        }

        const firstMoleculeAtoms = this._atoms.slice(0, index + 1);
        const secondMoleculeAtoms = this._atoms.slice(index + 1);

        this._atoms = firstMoleculeAtoms;
        return new Molecule(null, null, secondMoleculeAtoms);
    }

    toJSON() {
        return {
            ...this,
            type: 'Molecule'
        };
    }
}

export default Molecule;