import ScriptureAtom from "./Biblical/ScriptureAtom";

class ScriptureContext {
    before?: ScriptureAtom;
    after?: ScriptureAtom;
    hasContext: boolean;
    constructor(before?: ScriptureAtom, after?: ScriptureAtom) {
        this.before = before;
        this.after = after;
        this.hasContext = this.before !== undefined || this.after !== undefined;
    }
}

export default ScriptureContext;