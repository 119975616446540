import StrongsAtom from "../../model/Biblical/StrongsAtom";
import React, {useEffect, useState} from "react";
import StrongsEntryComponent from "./StrongsEntryComponent";

type Props = {
    atom: StrongsAtom;
    onAddToNotes?: (scripture: StrongsAtom) => void;
    onClose?: () => void;
};

const StrongsNavigatorComponent: React.FC<Props> = (props) => {
    const [entries, setEntries] = useState<StrongsAtom[]>([]);

    useEffect(() => {
        setEntries([props.atom]);
    }, [props.atom]);

    const onStrongsClick = (strongs: string): void => {
        const exists = entries.find(entry => entry.strongs === strongs);
        if (exists) {
            const newEntries = entries.filter(entry => entry.strongs !== strongs);
            setEntries(newEntries);
        } else {
            const newAtom = new StrongsAtom(null, null, strongs);
            setEntries([...entries, newAtom]);
        }
    };

    const onClose = (strongs: string, index: number): void => {
        if (index === 0) {
            if (props.onClose) props.onClose();
        }
        else {
            setEntries(entries.filter(entry => entry.strongs !== strongs));
        }
    };

    return (
        <div>
            {entries.map((entry, i) => (
                <StrongsEntryComponent
                    key={entry.strongs}
                    strongs={entry}
                    onStrongsClick={onStrongsClick}
                    onAddToNotes={props.onAddToNotes}
                    onClose={() => onClose(entry.strongs, i)}
                />
            ))}
        </div>
    );
};

export default StrongsNavigatorComponent;