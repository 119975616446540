import ScriptureAtom from "../Biblical/ScriptureAtom";
import MarkdownAtom from "./MarkdownAtom";
import Atom from "./Atom";
import {RelatedAtom} from "./RelatedAtom";
import StrongsAtom from "../Biblical/StrongsAtom";

export default class AtomDeserializer {
    static deserializeArray(json: any[] | undefined): Atom[] {
        if (json === undefined) {
            return [];
        }
        return json.map((atomJSON: any) => this.deserialize(atomJSON));
    }

    static deserialize(json: any): Atom {
        switch (json.type) {
            case "Atom":
                return new Atom(json.id, json.etag);
            case "Scripture":
                return ScriptureAtom.fromJSON(json);
            case "Markdown":
                return MarkdownAtom.fromJSON(json);
            case "Related":
                return RelatedAtom.fromJSON(json);
            case "Strongs":
                return StrongsAtom.fromJSON(json);
            default:
                throw new Error(`Unknown atom type: ${json.type}`);
        }
    }
}