import React from 'react';
import './TextWithStrongsNumbersComponent.css';

interface StrongNumberProps {
    strongs: string;
    onStrongsClick: (strongs: string) => void;
}

const StrongsNumberComponent: React.FC<StrongNumberProps> = ({strongs, onStrongsClick}) => {
    return (
        <span className="strong-number" onClick={() => onStrongsClick(strongs)}>
            {strongs}
        </span>
    );
};

interface TextWithStrongNumbersProps {
    text: string | undefined;
    onStrongsClick: (strongs: string) => void;
}

const TextWithStrongNumbersComponent: React.FC<TextWithStrongNumbersProps> = ({text, onStrongsClick}) => {
    const regex = /\b([GH])(\d+)\b/g;

    const parsedText = text?.split(regex).map((part, index) => {
        if (index % 3 === 1) {
            return <StrongsNumberComponent key={index} strongs={part as 'H' | 'G' + text.split(regex)[index + 1]}
                                           onStrongsClick={onStrongsClick}/>;
        } else if (index % 3 !== 0) {
            return null;
        }
        return part;
    });

    return <div>{parsedText}</div>;
};

export default TextWithStrongNumbersComponent;
