interface CacheEntry<T> {
    promise: Promise<T>;
    timeoutId: ReturnType<typeof setTimeout>;
}

export class CacheService<T> {
    private cache: Record<string, CacheEntry<T>> = {};

    constructor(private ttl: number = 60000) {} // Default time-to-live (TTL) is 60 seconds

    // Set a cache entry
    set(key: string, promise: Promise<T>): void {
        // Clear any existing timeout to avoid memory leaks if set is called again before TTL
        if (this.cache[key]) {
            clearTimeout(this.cache[key].timeoutId);
        }

        // Set a timeout to automatically delete the entry after TTL
        const timeoutId = setTimeout(() => this.delete(key), this.ttl);

        // Store the promise and the timeoutId in the cache
        this.cache[key] = { promise, timeoutId };
    }

    // Retrieve a cache entry, if it exists
    get(key: string): Promise<T> | undefined {
        return this.cache[key]?.promise;
    }

    // Delete a cache entry
    private delete(key: string): void {
        clearTimeout(this.cache[key]?.timeoutId); // Clear timeout to avoid memory leaks
        delete this.cache[key]; // Remove the entry from the cache
    }

    // Reset the cache for a specific key or the entire cache
    reset(key?: string): void {
        if (key) {
            Object.keys(this.cache)
                .filter(k => k.startsWith(key))
                .forEach(k => this.delete(k));
        } else {
            Object.keys(this.cache)
                .forEach(k => this.delete(k));
        }
    }
}