import React from "react";
import StyledChipContainer from "../../styles/StyledChipContainer";
import StyledChip from "../../styles/StyledChip";
import {RelatedAtom} from "../../model/Core/RelatedAtom";
import {SemanticRef} from "../../model/SemWeb/SemanticRef";

type Props = {
    atom: RelatedAtom;
    onSelectPost: (ref: SemanticRef) => void;
};

const RelatedComponent: React.FC<Props> = (props) => {
    let {atom, onSelectPost} = props;
    return (atom.refs.length > 0 ? (
            <StyledChipContainer>
                {atom.label && (<span>{atom.label}: </span>)}
                {atom.refs.map(r => (
                    <StyledChip label={r.label} key={r.uri} onClick={() => onSelectPost(r)}/>
                ))}
            </StyledChipContainer>
        ) : null
    );
};

export default RelatedComponent;