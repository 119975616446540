import React from "react";
import MDEditor from "@uiw/react-md-editor";
import StyledNotesContainer from "../../styles/StyledNotesContainer";
import StyledNotesTypography from "../../styles/StyledNotesTypography";

type Props = {
    notes?: string;
};

const NotesComponent: React.FC<Props> = (props) => {
    let {notes} = props;

    return (notes !== undefined ? (
        <StyledNotesContainer>
            <StyledNotesTypography>
                <div data-color-mode="dark">
                    <MDEditor.Markdown
                        source={notes} disableCopy={true} style={{ backgroundColor:"transparent" }}/>
                </div>
            </StyledNotesTypography>
        </StyledNotesContainer>) : null
    );
};

export default NotesComponent;