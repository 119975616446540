import BibleService from "../services/BibleService";
import BookAbbr from "./Book";
import {SemanticRef} from "./SemWeb/SemanticRef";

class ScriptureRef {
    static bookAbbr: BookAbbr[];
    bookName: string;
    chapter: number;
    from: number;
    to: number;

    constructor(bookName: string, chapter: number, from?: number, to?: number) {
        this.bookName = ScriptureRef.normalizeBookName(bookName);
        this.chapter = chapter;
        if (from === undefined && to === undefined) {
            this.from = 1;
            this.to = 1024;
        } else if (to === undefined) {
            this.from = from ?? 1;
            this.to = this.from;
        } else if (from === undefined) {
            this.from = 1;
            this.to = to;
        } else {
            this.from = from;
            this.to = to;
        }
    }

    public toUri(wholeChapter: boolean = false, searchText: string | undefined = undefined) {
        let uri: string;
        if (wholeChapter || (this.from === 1 && this.to === 1024)) {
            uri = `bible:${this.bookName}/${this.chapter}`;
        }
        else {
            uri = `bible:${this.bookName}/${this.chapter}/${this.from}${this.to === this.from ? "" : "-" + this.to}`;
        }
        if (searchText) {
            uri += `?search=${searchText}`;
        }
        return uri;
    }

    public toSemanticRef(): SemanticRef {
        return SemanticRef.fromBible(this.bookName, this.chapter, this.from, this.to);
    }

    public static fromUri(uri: string) {
        const parts = uri.split('/');
        const book = parts[0].split(':')[1];
        const chapter = parts[1];
        const verses = parts.length === 2 ? undefined : parts[2];
        return this.fromUriParams(book, chapter, verses)
    }

    public static fromUriParams(book: string, chapter: string, verses?: string) {
        const chapterNo = parseInt(chapter, 10);
        let fromVerse = 1;
        let toVerse = 1024;
        if (verses === undefined) {
            fromVerse = 1;
            toVerse = 1024;
        }
        else {
            const verseParts = verses.split('-');
            fromVerse = parseInt(verseParts[0], 10);
            toVerse = (verseParts.length >= 2) ? parseInt(verseParts[1]) : fromVerse;
        }
        return new ScriptureRef(book, chapterNo, fromVerse, toVerse);
    }

    public static async FromEncodedRef(encodedRef: number): Promise<ScriptureRef> {
        const bookIndex = (encodedRef >> 16) & 0x7F; // 7 bits for book
        const chapter = (encodedRef >> 8) & 0xFF;   // 8 bits for chapter
        const verse = encodedRef & 0xFF;            // 8 bits for verse

        let bookName: string;
        try {
            bookName = await BibleService.getBookNameByIndex(bookIndex);
        } catch (e) {
            throw Error(`Invalid encoded verse reference ${encodedRef} (bookIndex of ${bookIndex} is invalid.`);
        }
        const ref = new ScriptureRef(bookName, chapter, verse, verse);
        if (ref === undefined) {
            throw Error(`Invalid encoded verse reference ${encodedRef} (book=${!bookName}, chapter=${chapter}, verse=${verse}`);
        }
        return ref;
    }

    public numVerses() {
        return this.to - this.from + 1;
    }

    public static areEqual(a: ScriptureRef | undefined, b: ScriptureRef | undefined): boolean {
        if (a === undefined && b === undefined) {
            return true;
        }
        if (a === undefined || b === undefined) {
            return false;
        }
        return a.bookName === b.bookName && a.chapter === b.chapter && a.from === b.from && a.to === b.to;
    }

    public isWholeChapter(): boolean {
        return this.from === 1 && this.to === 1024;
    }

    public static parse(str: string): ScriptureRef {
        const ref = this.tryParse(str);
        if (ref === undefined) {
            throw new Error(`The scripture reference provided, '${str}', is incorrectly formatted. Please try something like: John 3, John 3:16, John 3:16-17.`);
        }
        return ref;
    }

    public static tryParse(str: string): ScriptureRef | undefined {
        if (str.startsWith("bible:"))
            return ScriptureRef.fromUri(str);
        const regex = /(?<bookNo>\d{1,5}\s{0,3})?(?<bookName>[A-Za-z\s]{1,32})(\s{0,3}(?<chapter>\d+))?(:(?<from>\d{1,3}))?(-(?<to>\d{1,3}))?/;
        const result = str.match(regex);
        const toNumber = (x: string | undefined): number | undefined => {
            if (x === undefined) return undefined;
            const n = parseInt(x, 10);
            return isNaN(n) ? undefined : n;
        };
        if (!result || !result.groups) {
            return undefined;
        }
        const g = result.groups;
        let bookName = g?.bookName.trim().toLowerCase(); // the regex needs to support 'Song of Solomon'. The greedy operation then adds a space to the end of the book name.
        const chapter = toNumber(g?.chapter);
        if (bookName === undefined || chapter === undefined) {
            return undefined;
        }
        bookName = g?.bookNo === undefined ? bookName : `${g?.bookNo.trim()} ${bookName}`;
        return new ScriptureRef(bookName, chapter, toNumber(g?.from), toNumber(g?.to));
    }

    public toString(verse?: number): string {
        if (verse !== undefined) {
            return `${this.toTitleCase(this.bookName)} ${this.chapter}:${verse}`;
        }
        if (this.from === 1 && this.to === 1024) {
            return `${this.toTitleCase(this.bookName)} ${this.chapter}`;
        }
        if (this.to === this.from) {
            return `${this.toTitleCase(this.bookName)} ${this.chapter}:${this.from}`;
        }
        return `${this.toTitleCase(this.bookName)} ${this.chapter}:${this.from}-${this.to}`;
    }

    private static normalizeBookName(name: string, normalizeBookName: boolean = false): string {
        return normalizeBookName ? name.toLowerCase() : name;
    }

    public isMatch(ref: ScriptureRef): boolean {
        if (this.bookName !== ref.bookName || this.chapter !== ref.chapter) {
            return false;
        }
        const result = ref.from <= this.to && ref.to >= this.from;
        return result;
    }

    private toTitleCase(str: string): string {
        return str.replace(
            /\w\S*/g,
            function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }
}

export default ScriptureRef;