import React, {useState} from "react";
import StyledChipContainer from "../../styles/StyledChipContainer";
import {Chip} from "@mui/material";
import {RelatedAtom} from "../../model/Core/RelatedAtom";
import {SemanticRef} from "../../model/SemWeb/SemanticRef";
import AddIcon from "../icons/AddIcon";
import SearchComponent from "../search/SearchComponent";

type Props = {
    atom: RelatedAtom;
    canRemove: boolean;
    onSelect: (ref: SemanticRef) => void;
    onAdd: (ref: SemanticRef) => void;
};

const RelatedEditorComponent: React.FC<Props> = ({atom, canRemove, onSelect, onAdd}) => {
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [refs, setRefs] = useState<SemanticRef[]>(atom.refs);

    const handleAddTopic = (input: SemanticRef) => {
        if (input && !refs.find(r => r.uri === input.uri)) {
            const newRefs = [...refs, input];
            setRefs(newRefs);
            atom.refs = newRefs; // Update atom.refs to maintain consistency
            onAdd(input);
            //onSelect(input);
        }
        setIsSearchActive(false); // Hide the search input after adding
    };

    const handleRemoveTopic = (uri: string) => {
        const newRefs = refs.filter(r => r.uri !== uri);
        setRefs(newRefs);
        atom.refs = newRefs;
    };

    return (
        <div>
            <StyledChipContainer>
                {atom.label && <span>{atom.label}: </span>}
                {refs.map(r => {
                    const chipProps = {
                        onDelete: canRemove ? () => handleRemoveTopic(r.uri) : undefined,
                    };

                    return (
                        <Chip
                            label={r.label}
                            key={r.uri}
                            {...chipProps}
                            sx={{margin: '5px'}}
                            onClick={() => onSelect(r)}
                        />
                    );
                })}
                {!isSearchActive && (<AddIcon onClick={() => setIsSearchActive(true)}/>)}
            </StyledChipContainer>
            {isSearchActive && (<SearchComponent onSelect={handleAddTopic} searchBible={false} searchBibleBooks={false} searchPosts={true} />)}
        </div>
    );
};

export default RelatedEditorComponent;