import React from "react";
import SimpleVerseComponent from "./SimpleVerseComponent";
import {Box, Card, CardContent, Typography} from "@mui/material";
import ErrorMessageComponent from "../errors/ErrorMessageComponent";
import LoadingComponent from "../content/LoadingComponent";
import LazyScripture from "../../model/LazyScripture";
import {useNavigate} from "react-router-dom";
import ScriptureAtom from "../../model/Biblical/ScriptureAtom";

type Props = {
    stringRef: string;
};

const SoloVerseComponent: React.FC<Props> = (props) => {
    let {stringRef} = props;
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [error, setError] = React.useState<Error | undefined>();
    const [scripture, setScripture] = React.useState<ScriptureAtom | undefined>();
    const navigate = useNavigate();

    React.useEffect(() => {
        const lazy = LazyScripture.fromReference(stringRef);
        lazy.getValue()
            .then(result => setScripture(result))
            .catch(error => setError(error))
            .finally(() => setIsLoading(false));
    }, [stringRef]);

    if (error !== undefined) {
        return <ErrorMessageComponent message={error.message}/>;
    }

    return (
        <div>
            <Card key={scripture?.ref?.toString()} >
                <CardContent>
                    {(isLoading && <LoadingComponent message={"Loading bible..."}/>)}
                    {(!isLoading && scripture !== undefined &&
                        <Box display="flex" flexDirection="column">
                            <SimpleVerseComponent scripture={scripture} />
                            <div className="verse-reference" style={{marginLeft: "auto"}}
                                 onClick={() => navigate(`/reader/${encodeURIComponent(scripture.ref.toString())}`)}>
                                <Typography variant="body1" color="text.secondary" textAlign="right">
                                    {scripture.ref.toString()}
                                </Typography>
                            </div>
                        </Box>)}
                </CardContent>
            </Card>
        </div>
    );
};

export default SoloVerseComponent;