import React, {useState} from "react";
import {Typography} from "@mui/material";
import StyledContentHeaderEditable from "../../styles/StyledContentHeaderEditable";

interface EditableTitleProps {
    currentTitle: string;
    onChange: (newTitle?: string) => void;
}

export const EditableTitleComponent: React.FC<EditableTitleProps> = ({currentTitle, onChange }) => {
    const [title, setTitle] = useState<string | undefined>(currentTitle);
    const [isEditing, setIsEditing] = useState<boolean>(false);

    const onTitleChange = (newValue: string) => {
        setTitle(newValue);
        onChange(title);
    };

    return (
        <StyledContentHeaderEditable onClick={() => !isEditing && setIsEditing(true)}>
            {isEditing ? (
                <input
                    type="text"
                    value={title}
                    onChange={(e) => onTitleChange(e.target.value)}
                    onBlur={() => setIsEditing(false)}
                    autoFocus
                    style={{fontSize: '1.25rem', fontWeight: 'bold', width: '100%'}} // Adjust styles as needed
                />
            ) : (
                <Typography variant="h5">{title}</Typography>
            )}
        </StyledContentHeaderEditable>
    );
};
