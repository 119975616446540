import {IError} from "./IError";

export class BaseError implements IError {
    status: number;
    title: string;
    details: string;

    constructor(status: number, title: string, details: string) {
        this.status = status;
        this.title = title;
        this.details = details;
    }

    // Extract the error body construction into a separate method for easier overriding
    public getErrorBody(): any {
        return {
            status: this.status,
            title: this.title,
            details: this.details
        };
    }
}