import React, {useEffect, useState} from 'react';
import {APP_NAME} from '../constants';
import BiblePage from './BiblePage';
import PostViewPage from './PostViewPage';
import SearchPage from './SearchPage';
import {Route, Routes, useNavigate} from "react-router-dom";
import PageNotFoundComponent from "../components/errors/PageNotFoundComponent";
import HelpComponent from "../components/content/HelpComponent";
import AboutPage from "./AboutPage";
import MenuComponent from "../components/layout/MenuComponent";
import styled from "@emotion/styled";
import StyledAppBar from "../styles/StyledAppBar";
import PostEditorPage from "./PostEditorPage";
import {CurieService} from "../services/CurieService";
import {SemanticRef} from "../model/SemWeb/SemanticRef";
import AppStateMenuComponent from "../components/layout/AppStateMenuComponent";
import RtarErrorBoundary from "../components/errors/RtarErrorBoundary";
import AppStateBoundary from "../components/errors/AppStateBoundary";
import SearchComponent from "../components/search/SearchComponent";
import StrongsPage from "./StrongsPage";

const CenteredBox = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`;

const NavigatorPage: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState<SemanticRef | string>('');
    const navigate = useNavigate();

    useEffect(() => {
        document.title = APP_NAME;
    }, []);

    const handleOptionSelected = async (value: SemanticRef) => {
        setSearchTerm(value ?? '');
        navigate(CurieService.toFullUri(value.uri, false));
    };

    const onSelectPost = (ref: SemanticRef) => {
        navigate(CurieService.toFullUri(ref.uri, false));
    };

    return (
        <>
            <StyledAppBar position="static" elevation={0}>
                <CenteredBox>
                    <MenuComponent/>
                    <SearchComponent onSelect={handleOptionSelected} searchBible={true} searchBibleBooks={true} searchPosts={true} />
                    <AppStateMenuComponent/>
                </CenteredBox>
            </StyledAppBar>
            <div>
                <Routes>
                    <Route path="/" element={<HelpComponent width='640px'/>}/>
                    <Route path="posts/:id" element={
                        <AppStateBoundary requiresInternet={true} requiresLogin={false}>
                            <RtarErrorBoundary resetKeys={[searchTerm]}>
                                <PostViewPage onSelectPost={onSelectPost}/>
                            </RtarErrorBoundary>
                        </AppStateBoundary>
                    }/>
                    <Route path="reader/:book/:chapter/:verses?" element={
                        <RtarErrorBoundary resetKeys={[searchTerm]}>
                            <BiblePage onSelectPost={onSelectPost}/>
                        </RtarErrorBoundary>}/>
                    <Route path="search/:searchText" element={
                        <RtarErrorBoundary resetKeys={[searchTerm]}>
                            <SearchPage onSelectPost={onSelectPost}/>
                        </RtarErrorBoundary>}/>
                    <Route path="about" element={<AboutPage/>}/>
                    <Route path="notes" element={<PostEditorPage onSelectPost={onSelectPost}/>}/>
                    <Route path="strongs/:strongs" element={<StrongsPage />}/>
                    <Route path='*' element={<PageNotFoundComponent/>}/>
                </Routes>
            </div>
        </>
    );
};

export default NavigatorPage;