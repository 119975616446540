import {nanoid} from "nanoid";

export class IdService {
    public static generateTempId(): string {
        return `__${nanoid()}`;
    }

    public static isTempId(id: string | null) {
        if (id === null) return true;
        return id.startsWith('__');
    }
}