import {SvgIcon} from "@mui/material";
import StyledAction from "../../styles/StyledActionSvgIcon";
import React from "react";

type Props = {
    onClick: (event: React.MouseEvent<SVGSVGElement>) => void;
}

function ShareIcon(props: Props) {
    return (
        <StyledAction>
            <SvgIcon viewBox="0 0 24 24" fontSize="small" onClick={event => props.onClick(event)}>
                <path d="M19.333,14.667a4.66,4.66,0,0,0-3.839,2.024L8.985,13.752a4.574,4.574,0,0,0,.005-3.488l6.5-2.954a4.66,4.66,0,1,0-.827-2.643,4.633,4.633,0,0,0,.08.786L7.833,8.593a4.668,4.668,0,1,0-.015,6.827l6.928,3.128a4.736,4.736,0,0,0-.079.785,4.667,4.667,0,1,0,4.666-4.666ZM19.333,2a2.667,2.667,0,1,1-2.666,2.667A2.669,2.669,0,0,1,19.333,2ZM4.667,14.667A2.667,2.667,0,1,1,7.333,12,2.67,2.67,0,0,1,4.667,14.667ZM19.333,22A2.667,2.667,0,1,1,22,19.333,2.669,2.669,0,0,1,19.333,22Z"/>
            </SvgIcon>
        </StyledAction>
    );
}

export default ShareIcon;