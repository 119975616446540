import {BaseError} from "./BaseError";
import {IError} from "./IError";
import {InnerError} from "./InnerError";

export class ServerError extends BaseError {
    internalDetails: string;
    innerError?: IError;
    constructor(internalDetails: string, innerError?: any) {
        super(500, "Internal Server Error", "Please try again.");
        this.innerError = ServerError.toIError(innerError);
        this.internalDetails = internalDetails;
    }

    public static toIError(error: any): IError {
        if (typeof error.getResponse === 'function') {
            return error;
        }

        const message = error instanceof Error ? error.message : '';
        const stack = error instanceof Error ? error.stack : '';
        return new InnerError(message, stack);
    }

    public getErrorBody(): any {
        const baseBody = super.getErrorBody();
        return {
            ...baseBody,
            internalDetails: this.internalDetails,
            innerError: this.innerError
        };
    }
}