import {Typography} from "@mui/material";
import React from "react";
import ContentSubTitle from "../../styles/StyledContentSubTitle";

type Props = {
    title?: string;
};

const VerseTitleComponent: React.FC<Props> = (props) => {
    let {title} = props;

    return (title ? (
        <ContentSubTitle>
            <Typography variant="h6" color="text.secondary">
                {title}
            </Typography>
        </ContentSubTitle>) : null
    );
};

export default VerseTitleComponent;