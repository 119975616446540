import {Menu, MenuItem, Typography} from "@mui/material";
import React from "react";
import CopyIcon from "./CopyIcon";
import ShareIcon from "./ShareIcon";
import CopyLinkIcon from "./CopyLinkIcon";

type Props = {
    onCopyClick: () => void;
    onCopyLinkClick: () => void;
}

function ShareIconButton(props: Props) {
    const [anchorEl, setAnchorEl] = React.useState<null | SVGSVGElement>(null);

    const handleShareMenuOpen = (event: React.MouseEvent<SVGSVGElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleShareMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <ShareIcon onClick={handleShareMenuOpen}/>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleShareMenuClose}
            >
                <MenuItem key="copy" onClick={props.onCopyClick}>
                    <CopyIcon/>
                    <Typography variant="body1">Copy</Typography>
                </MenuItem>
                <MenuItem key="copy-link" onClick={props.onCopyLinkClick}>
                    <CopyLinkIcon/>
                    <Typography variant="body1">Copy Link</Typography>
                </MenuItem>
            </Menu>
        </>
    )
        ;
}

export default ShareIconButton;


