import styled from '@emotion/styled';
import {Chip} from "@mui/material";

const StyledChip = styled(Chip)(
    ({ theme }) => ({
        borderRadius: '1rem',
        padding: 0,
        marginRight: '0.5rem',
        verticalAlign: 'center',
        backgroundColor: '#343747'
    })
);

export default StyledChip;