import React from "react";
import AddNoteIcon from "../icons/AddNoteIcon";
import StyledCardActions from "../../styles/StyledCardActions";
import AddRelatedIcon from "../icons/AddRelatedIcon";

type Props = {
    onAddNote: () => void;
    onAddRelated: () => void;
}

const NotesActionsComponent: React.FC<Props> = (props) => {
    return (<>
        <StyledCardActions>
            <AddNoteIcon onClick={props.onAddNote}/>
            <AddRelatedIcon onClick={props.onAddRelated}/>
        </StyledCardActions>
    </>);
}

export default NotesActionsComponent;