import React from 'react';
import {Typography} from "@mui/material";
import SoloVerseComponent from "../components/bible/SoloVerseComponent";
import StyledContentHeader from "../styles/StyledContentHeader";
import StyledContentContainer from "../styles/StyledContentContainer";

const AboutPage: React.FC = () => {
    return (
        <>
            <StyledContentHeader>
                <Typography variant="h5">
                    About Rebuilt
                </Typography>
            </StyledContentHeader>
            <StyledContentContainer>
                <img src="/about-profile-pic.png" alt="Profile" style={{
                    width: '148px',
                    height: '148px',
                    padding: '6px',
                    marginTop: '12px',
                    marginRight: '8px',
                    float: "left"
                }}/>
                <Typography variant="body1" className="notes">
                    Thank you for visiting Rebuilt. I pray that this site will provide living water for your
                    soul, but even more than that, to guide you to experience His abiding presence by allowing the Holy
                    Spirit to teach you. I would rather that you be taught by the Holy Spirit Himself, and I pray that
                    these
                    tools will help you on that journey. Peter's revelation that Jesus is the Son of God was powerful
                    because it was not revealed by man but by God. In the same way, we all have need of teachers, but
                    the
                    teacher's job is to guide us to be taught by the Holy Spirit.
                    <br/><br/>
                    The topics and notes on this site are revelations that I have received from the Holy Spirit along
                    the
                    journey. These have been transformative in conforming me into His likeness, and I pray that they
                    will
                    produce as much, or greater harvest in your life.
                    <br/><br/>
                    I created this site in obedience to a prophetic word that I received in Moravian Falls in 2021. The
                    word
                    was that these revelations would unlock kingdom mysteries for the church. I know these have had a
                    profound
                    impact on my life, and I leave it to the Lord to multiply this work through His all-sufficient
                    grace,
                    without which nothing is possible.
                    <br/><br/>
                    My faith has been influenced by many heroes in the faith, some of whom are alive today and some who
                    have
                    moved on to glory. These include John Wesley, Smith Wigglesworth, John G. Lake, Rodney Howard
                    Browne,
                    Rick Joyner, Myles Munroe, Pete Cabrera Jr., to name but a few. These have significantly directed
                    the course
                    of my life.
                </Typography>
                <SoloVerseComponent stringRef="Isaiah 58:12"/>
                <Typography variant="body1" className="notes">
                    The name, "Rebuilt" originates from Isaiah 58:12, where those who follow God's ways will <strong>rebuild
                    the
                    ancient ruins</strong> (rtar.org). What lies in ruins is faith, which the ancients were commended
                    for. The faith
                    of Abel, Enoch, Abraham, Jacob, Moses, Joseph, Gideon, David, Daniel, and all the others stands in
                    stark contrast to much of what we see today. Although there have been great heroes in the faith
                    since then,
                    and parts of the city of faith has been rebuilt, there are still large parts that lie in ruins. It
                    is time for
                    us to press in! Even the least in God's kingdom is greater than the greatest of those heroes. What
                    has been
                    made available on that cross through the Holy Spirit is more than those heroes of old could even
                    comprehend, yet
                    we walk in so little of what is available.
                    <br/><br/>
                    Our Lord said that when He returns, it would be difficult to find this kind of faith, yet God's
                    promise
                    given to Daniel will come to pass in the last days, that we will see the greatest champions of the
                    Lord in
                    our time.
                </Typography>
                <SoloVerseComponent stringRef="Daniel 11:32"/>
                <Typography variant="body1" className="notes">
                    The Lord is longing, waiting, and searching to find those of great faith, with loyal hearts, so that
                    He
                    may show Himself strong on their behalf.
                </Typography>
                <SoloVerseComponent stringRef="2 Chronicles 16:9"/>
                <Typography variant="body1" className="notes">
                    The Lord has sent "the eyes of the Lord" to roam the earth, looking for those who will dare to
                    believe
                    Him at His word so that He can show Himself strong on our behalf. And no eye has seen, nor ear
                    heard,
                    nor mind conceived what God has prepared. So often we think we're trying to reach heaven, but in
                    reality,
                    heaven is trying to reach us! The Lord is longing to demonstrate His power in and through us.
                </Typography>
                <SoloVerseComponent stringRef="Matthew 8:10"/>
                <Typography variant="body1" className="notes">
                    It's hard to imagine that there would be anything that would blow Jesus' mind, but this centurion's
                    faith
                    accomplished just that. We all want to hear, 'well done, good and faithful servant,' but what an
                    honor to be
                    named among those who had such a profound impact on Jesus. Jesus said that He had not found such
                    faith in
                    all of Israel. He was searching all of Israel for such faith! Jesus is still doing the same today,
                    He is
                    still looking for such faith.
                    <br/><br/>
                    My heart burns to see the last day church step into this kind of faith, daring to believe all that
                    He has
                    promised.
                    <br/><br/>
                    Shalom,<br/><br/>
                    Troy Dalldorf
                </Typography>
            </StyledContentContainer>
        </>
    );
};

export default AboutPage;