import ProblemDetails from "./ProblemDetails";
import {ServerError} from "./errors/ServerError";
import {PagedResult} from "./PagedResult";

export default class ApiClient {
    getAccessToken: (required: boolean) => Promise<string|undefined>;
    constructor(getAccessToken : (required: boolean) => Promise<string|undefined>) {
        this.getAccessToken = getAccessToken;
    }

    public async getSingle<TEntity>(
        url: string,
        transformEntity: (json: any) => TEntity,
        config: RequestInit = {}
    ): Promise<TEntity> {
        const result = await this.getInternal<TEntity>(url, transformEntity, config);
        return result as TEntity;
    }

    public async getList<TEntity>(
        url: string,
        transformEntity: (json: any) => TEntity,
        config: RequestInit = {}
    ): Promise<PagedResult<TEntity>> {
        const result = await this.getInternal<TEntity>(url, transformEntity, config);
        return result as PagedResult<TEntity>;
    }

    private async getInternal<TEntity>(
        url: string,
        transformEntity: (json: any) => TEntity,
        config: RequestInit = {}
    ): Promise<TEntity | PagedResult<TEntity>> {
        const accessToken = await this.getAccessToken(false);

        // Dynamically construct headers
        const headers = new Headers(config.headers || {});
        if (accessToken) {
            headers.append('Authorization', `Bearer ${accessToken}`);
        }

        try {
            const response = await fetch(url, {
                ...config,
                method: 'GET', // You might want to reconsider setting method here if you're passing `config`, as it might override what's passed in `config`
                headers: headers,
            });
            return await this.handleResponse(response, transformEntity) as PagedResult<TEntity>;
        } catch (error) {
            // todo: parse the error
            throw new ServerError("Unable to fetch response from API.", error);
        }
    }

    public async post<TEntity>(
        url: string,
        body: any,
        transformEntity: (json: any) => TEntity,
        config: RequestInit = {}): Promise<null | TEntity> {
        const accessToken = await this.getAccessToken(false);
        const headers = new Headers(config.headers);
        headers.set('Content-Type', 'application/json'); // Set default header
        headers.set('Authorization', `Bearer ${accessToken}`); // Set auth header

        if (config.headers) {
            Object.entries(config.headers).forEach(([key, value]) => {
                headers.append(key, value as string);
            });
        }

        const response = await fetch(url, {
            ...config,
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        });
        return await this.handleResponse<TEntity>(response, transformEntity) as (null | TEntity);
    }

    public async put<TEntity>(
        url: string,
        body: any,
        transformEntity: (json: any) => TEntity,
        etag: string | null = null,
        config: RequestInit = {}): Promise<TEntity | null> {
        const accessToken = await this.getAccessToken(false);
        const headers = new Headers(config.headers);
        headers.set('Content-Type', 'application/json'); // Set default header
        headers.set('Authorization', `Bearer ${accessToken}`); // Set auth header
        if (etag) headers.set('If-Match', etag);

        if (config.headers) {
            Object.entries(config.headers).forEach(([key, value]) => {
                headers.append(key, value as string);
            });
        }

        const response = await fetch(url, {
            ...config,
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(body),
        });
        return await this.handleResponse<TEntity>(response, transformEntity) as (null | TEntity);
    }

    private async handleResponse<TEntity>(
        response: Response,
        transformEntity: (json: any) => TEntity
    ): Promise<null | TEntity | PagedResult<TEntity>> {
        if (!response.ok) {
            throw new ProblemDetails(response.status, await response.text());
        }

        if (response.status === 204) {
            return null; // Handle null case
        }

        const json = await response.json();

        // Automatically handle arrays
        if (Array.isArray(json)) {
            return { items: json.map(item => transformEntity(item)) } as PagedResult<TEntity>;
        }

        // Automatically handle paged results (assuming 'items' key for simplicity)
        if (json.items && Array.isArray(json.items)) {
            return {
                items: json.items.map((item: any) => transformEntity(item))
            } as PagedResult<TEntity>;
        }

        // Handle single entity
        return transformEntity(json);
    }
}