import React from 'react';
import NavigatorPage from './NavigatorPage';
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {APP_NAME} from '../constants';
import defaultTheme from '../themes/defaultTheme';
import {BrowserRouter} from "react-router-dom";
import Copyright from "../components/layout/Copyright";
import {SessionProvider} from "../contexts/SessionProvider";
import {MenuProvider} from "../contexts/MenuContext";
import {SnackbarProvider} from "../contexts/SnackbarContext";
import {Auth0Provider} from '@auth0/auth0-react';
import GlobalSnackbar from "../components/layout/GlobalSnackbar";
import {PostsProvider} from "../contexts/PostsContext";
import {AppStateProvider} from "../contexts/AppStateContext";
import {BibleProvider} from "../contexts/BibleContext";

function App() {
    document.title = APP_NAME;

    const api_regex = /^\/api\/.*/
    // if using "/api/" in the pathname, don't use React Router
    if (api_regex.test(window.location.pathname)) {
        return <div/> // must return at least an empty div
    } else {
        return (
            <Auth0Provider
                domain="dev-2bk6va8a4zdsegkf.us.auth0.com"
                clientId="PbqOf20L7hXDdbhaD8rPbSkX3zCcjfiR"
                useRefreshTokens={true}
                authorizationParams={{
                    redirect_uri: window.location.origin,
                    audience: "https://rtar.org",
                    scope: "profile email read:posts write:posts offline_access"
                }}>
                <AppStateProvider>
                    <BrowserRouter>
                        <ThemeProvider theme={defaultTheme}>
                            <CssBaseline/>
                            <BibleProvider>
                                <PostsProvider>
                                    <SessionProvider>
                                        <SnackbarProvider>
                                            <MenuProvider>
                                                <NavigatorPage/>
                                                <GlobalSnackbar/>
                                            </MenuProvider>
                                        </SnackbarProvider>
                                    </SessionProvider>
                                </PostsProvider>
                            </BibleProvider>
                            <Copyright/>
                        </ThemeProvider>
                    </BrowserRouter>
                </AppStateProvider>
            </Auth0Provider>
        );
    }
}

export default App;
