import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {useNavigate} from "react-router-dom";
import {useMenu} from "../../contexts/MenuContext";
import MenuIcon from "../icons/MenuIcon";

export default function MenuComponent() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { menuItems } = useMenu();
    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    }

    const gotoPage = (route: string) => {
        setAnchorEl(null);
        navigate(route);
    };

    const handleMenuItemClick = (action: () => void) => {
        handleClose();
        action();
    };

    const wrappedMenuItems = menuItems.map((item: React.ReactNode, index: number) => {
        if (React.isValidElement(item)) {
            const element = item as React.ReactElement<any>;
            if (typeof element.props.onClick === 'function') {
                return React.cloneElement(element, {
                    key: index,
                    onClick: () => handleMenuItemClick(element.props.onClick),
                });
            }
        }
        return item;
    });

    return (
        <>
            <MenuIcon
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleClick}>
            </MenuIcon>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {wrappedMenuItems}
                <MenuItem key="notes" onClick={() => gotoPage("notes")}>Notes</MenuItem>
                <MenuItem key="about" onClick={() => gotoPage("about")}>About</MenuItem>
            </Menu>
        </>
    );
}