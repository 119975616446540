import StyledAction from "../../styles/StyledActionSvgIcon";
import {SvgIcon} from "@mui/material";
import React from "react";

function ErrorIcon() {
    return (
        <StyledAction>
            <SvgIcon viewBox="0 0 24 24" fontSize="small" >
                <path
                    d="m12 5a1.5 1.5 0 0 1 -1.5-1.5v-2a1.5 1.5 0 0 1 3 0v2a1.5 1.5 0 0 1 -1.5 1.5zm1.5 17.5v-2a1.5 1.5 0 0 0 -3 0v2a1.5 1.5 0 0 0 3 0zm-8.5-10.5a1.5 1.5 0 0 0 -1.5-1.5h-2a1.5 1.5 0 0 0 0 3h2a1.5 1.5 0 0 0 1.5-1.5zm19 0a1.5 1.5 0 0 0 -1.5-1.5h-2a1.5 1.5 0 0 0 0 3h2a1.5 1.5 0 0 0 1.5-1.5zm-6.524-6.671.981-1.743a1.5 1.5 0 0 0 -2.613-1.473l-.982 1.743a1.5 1.5 0 0 0 .571 2.044 1.484 1.484 0 0 0 .735.194 1.5 1.5 0 0 0 1.308-.765zm-9.32 16.558.982-1.743a1.5 1.5 0 0 0 -2.614-1.473l-.981 1.743a1.5 1.5 0 0 0 2.613 1.473zm-2.256-13.32a1.5 1.5 0 0 0 -.57-2.043l-1.744-.981a1.5 1.5 0 0 0 -1.473 2.613l1.743.982a1.5 1.5 0 0 0 2.044-.571zm16.558 9.32a1.5 1.5 0 0 0 -.57-2.043l-1.743-.982a1.5 1.5 0 0 0 -1.473 2.614l1.743.981a1.5 1.5 0 0 0 2.043-.57zm-13.891-11.987a1.5 1.5 0 0 0 .571-2.043l-.982-1.744a1.5 1.5 0 0 0 -2.613 1.473l.981 1.743a1.5 1.5 0 0 0 1.308.764 1.484 1.484 0 0 0 .735-.193zm9.32 16.558a1.5 1.5 0 0 0 .57-2.043l-.981-1.743a1.5 1.5 0 0 0 -2.614 1.473l.982 1.743a1.5 1.5 0 0 0 2.043.57zm2.257-13.32 1.743-.982a1.5 1.5 0 0 0 -1.473-2.613l-1.743.981a1.5 1.5 0 0 0 1.473 2.614zm-16.558 9.319 1.743-.981a1.5 1.5 0 0 0 -1.473-2.614l-1.743.982a1.5 1.5 0 0 0 .738 2.806 1.483 1.483 0 0 0 .735-.193z"/>
            </SvgIcon>
        </StyledAction>
    );
}

export default ErrorIcon;