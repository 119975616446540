import React, { createContext, useContext, useState } from 'react';

interface SnackbarProps {
    children: React.ReactNode;
}

interface SnackbarState {
    open: boolean;
    message: string;
}

interface SnackbarContextType {
    snackbarState: SnackbarState;
    showMessage: (message: string) => void;
    closeSnackbar: () => void;
}

const SnackbarContext = createContext<SnackbarContextType>({
    snackbarState: { open: false, message: '' },
    showMessage: () => {}, // Dummy function for initial value
    closeSnackbar: () => {}, // Dummy function for initial value
});

export const useSnackbar = () => useContext(SnackbarContext);

export const SnackbarProvider = ({ children }: SnackbarProps) => {
    const [snackbarState, setSnackbarState] = useState({
        open: false,
        message: '',
    });

    const showMessage = (message: string) => {
        setSnackbarState({ open: true, message });
    };

    const closeSnackbar = () => {
        setSnackbarState({ open: false, message: '' });
    };

    return (
        <SnackbarContext.Provider value={{ showMessage, closeSnackbar, snackbarState }}>
            {children}
        </SnackbarContext.Provider>
    );
};