import React from "react";
import {Typography} from "@mui/material";

interface Props {
    width?: string;
}

const HelpComponent: React.FC<Props> = ({ width }) => {
    return (
        <div style={{
            display:"flex",
            //backgroundColor:'#3F5280',
            color: "text.primary",
            padding: '6px',
            marginTop: 3,
            marginBottom: 3,
            borderRadius:'12px',
            alignItems: 'center' }}>
            <Typography variant="body1">
                Welcome to the Rebuilt Bible and Journal. This is a fully functioning alpha version. I am working on a
                few idiosyncrasies and would appreciate your feedback. If you found this site, you probably already know
                my email address.<br/>
                Rebuilt offers a searchable NKJ translation of the Bible, as well as the option to journal and add
                scriptures. Journals connected to the verse can be accessed when reading the scripture. You can also
                utilize these capabilities to create your own library of themes while reading the Bible.
                Your diary is private, but you can share links to its entries.<br/>
                Log in and create an account by clicking the button in the upper right corner. Log in to view your
                journal. The top-left option allows you to write and save notes.<br/>
                Some important features:<br/>
                - Access the goody bar by clicking on the verse number. The goody bar allows you to share, meld,
                discover or add journal entries, access Strong's definitions,
                and add to your current note.
                - Melding connects neighboring verses for goody bar activities (sharing, adding to notes, etc.).<br/>
                - Use the search bar to find journal entries, scriptures, and scripture searches.<br/>
                - You can use any notation for scriptures, such as Rm1, Rom1, Romans 1, and so on.<br/>
            </Typography>
        </div>
    );
};

export default HelpComponent;