import StyledAction from "../../styles/StyledActionSvgIcon";
import {SvgIcon} from "@mui/material";
import React from "react";

type Props = {
    onClick: () => void;
}

function AddRelatedIcon(props: Props) {
    return (
        <StyledAction>
            <SvgIcon viewBox="0 0 24 24" fontSize="small" onClick={props.onClick}>
                <path
                    d="M24,11.984a4,4,0,0,0-3.957-4A9.05,9.05,0,0,0,16,3.949a4,4,0,0,0-7.99,0A9.05,9.05,0,0,0,3.957,7.988a3.995,3.995,0,0,0-.016,7.989,9.044,9.044,0,0,0,4.064,4.074,4,4,0,0,0,7.99,0,9.044,9.044,0,0,0,4.064-4.074A4,4,0,0,0,24,11.984ZM12,2a2,2,0,1,1-2,2A2,2,0,0,1,12,2ZM2,11.984a2,2,0,1,1,2,2A2,2,0,0,1,2,11.984ZM12,22a2,2,0,1,1,2-2A2,2,0,0,1,12,22Zm3.478-3.939a3.965,3.965,0,0,0-6.956,0,7.062,7.062,0,0,1-2.59-2.6,3.966,3.966,0,0,0,.015-6.957,7.052,7.052,0,0,1,2.575-2.57,3.965,3.965,0,0,0,6.956,0,7.052,7.052,0,0,1,2.575,2.57,3.966,3.966,0,0,0,.015,6.957A7.062,7.062,0,0,1,15.478,18.061ZM20,13.983a2,2,0,1,1,2-2A2,2,0,0,1,20,13.983Z"/>
            </SvgIcon>
        </StyledAction>
    );
}

export default AddRelatedIcon;