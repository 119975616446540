import React from "react";
import { Typography} from "@mui/material";
import CloseIcon from "../icons/CloseIcon";
import PostsIcon from "../icons/NotesIcon";
import ToggleContextIcon from "../icons/ToggleContextIcon";
import MeldIcon from '../icons/MeldIcon';
import StrongsIcon from '../icons/StrongsIcon';
import {useNavigate} from "react-router-dom";
import StyledCardActions from "../../styles/StyledCardActions";
import ShareIconButton from "../icons/ShareIconButton";
import AddToNotesIcon from "../icons/AddNoteIcon";
import ScriptureAtom from "../../model/Biblical/ScriptureAtom";
import {useSnackbar} from "../../contexts/SnackbarContext";
import {CurieService} from "../../services/CurieService";

type Props = {
    canClose: boolean;
    canExpandContext: boolean;
    isExpandedContext: boolean;
    onAddToNotes?: (scripture: ScriptureAtom) => void;
    onClose: () => void;
    onCopy: () => Promise<string>;
    onExpandContext: (expand: boolean) => void;
    onMeld?: (scripture: ScriptureAtom) => void;
    postsCount: number | null;
    onNotesClick: () => void;
    onStrongsClick: () => void;
    scripture: ScriptureAtom;
    showActions: boolean;
    searchText: string | undefined;
}

const VerseActionsComponent: React.FC<Props> = (props) => {
    const {canClose, canExpandContext, isExpandedContext, showActions, scripture} = props;
    const {showMessage} = useSnackbar();
    const navigate = useNavigate();

    const doCopy = async () => {
        const refText = await props.onCopy();
        showMessage(`'${refText}' copied to clipboard.`);
    };

    const doCopyLink = async () => {
        await navigator.clipboard.writeText(`https://rtar.org/reader/${encodeURIComponent(scripture.ref.toString())}`);
        showMessage(`Link to '${scripture.ref.toString()}' copied to clipboard.`);
    }

    // @ts-ignore
    return (<>
        <StyledCardActions>
            {showActions &&
                <>
                {canClose && <CloseIcon aria-label="copy" onClick={props.onClose}/>}
                    <ShareIconButton aria-label="share" onCopyClick={doCopy} onCopyLinkClick={doCopyLink}/>
                    {(props.onMeld !== undefined) && <MeldIcon aria-label="meld" onClick={() => props.onMeld && props.onMeld(scripture)} />}
                    <PostsIcon aria-label="notes" onClick={props.onNotesClick} noteCount={props.postsCount}/>
                    {canExpandContext && <ToggleContextIcon aria-label="notes" expanded={isExpandedContext} onClick={() => props.onExpandContext(true)}/>}
                    <StrongsIcon onClick={props.onStrongsClick} />
                    <AddToNotesIcon onClick={() => props.onAddToNotes && props.onAddToNotes(scripture)} />
                </>}
                <div className="verse-reference" style={{marginLeft: "auto"}}
                     onClick={() => navigate(CurieService.toFullUri(scripture.ref.toUri(false, props.searchText), false))}>
                    <Typography variant="body1" color="text.secondary">
                        {scripture.ref.toString()}
                    </Typography>
                </div>
        </StyledCardActions>
    </>);
}

export default VerseActionsComponent;