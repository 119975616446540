import React from "react";
import {Box} from "@mui/material";

const NoMatchingVersesComponent: React.FC = () => {
    return (
        <Box style={{
            width:'100%',
            display:"flex",
            justifyContent:"center"
        }}>
            Page Not Found
        </Box>
    );
};

export default NoMatchingVersesComponent;