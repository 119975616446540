import Atom from "./Atom";
import Block from "./Block";
import MarkdownAtom from "./MarkdownAtom";

class ContentAtom extends Atom {
    public block: Block;
    constructor(id: string | null, etag: string | null, block: Block) {
        super(id, etag);
        this.block = block;
    }

    toJSON() {
        return { ...this, type: 'Content' };
    }

    public getContent(): string {
        return this.block.getContent();
    }

    override getWordCount(): number {
        const words = this.getContent().split(/\s+/).filter(word => word.length > 0);
        return words.length;
    }

    public setContent(content: string) {
        this.block.setContent(content);
    }

    static fromJSON(json: any): ContentAtom {
        switch (json.type) {
            case 'Markdown':
                return MarkdownAtom.fromJSON(json);
            default:
                throw new Error(`Unknown content type: ${json.type}`);
        }
    }
}

export default ContentAtom;