import StyledAction from "../../styles/StyledActionSvgIcon";
import {SvgIcon, Typography, Box} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useAppState} from "../../contexts/AppStateContext";
import IconProps from "./IconProps";

function AppStateIcon({onClick, ...otherProps}: IconProps) {
    const {isLoggedIn, isOnline, loginError, checkLogin, checkInternet} = useAppState();
    const [highlight, setHighlight] = useState(false);
    const [lastDegradationTime, setLastDegradationTime] = useState<number>(0);
    const [showError, setShowError] = useState(false);
    const [attemptLogin, setAttemptLogin] = useState(false);

    checkLogin();
    checkInternet();

    useEffect(() => {
        const now = Date.now();

        if ((!isOnline || !isLoggedIn) && (now - lastDegradationTime > 1000)) { // Throttle to once per second
            setHighlight(true);
            setLastDegradationTime(now);

            const timer = setTimeout(() => {
                setHighlight(false);
            }, 1000); // Reset highlight state after 1 second

            return () => clearTimeout(timer);
        }
    }, [isLoggedIn, isOnline, lastDegradationTime]);

    useEffect(() => {
        if (attemptLogin) {
            setShowError(loginError !== undefined);
            setAttemptLogin(false);
        }
    }, [attemptLogin, loginError]);

    const handleIconClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (onClick) {
            onClick(e);
        }
        setAttemptLogin(true);
    };

    let path = "";
    const viewBox = "0 0 24 24";
    if (!isOnline) {
        path = "m18.113,21.655l.791.791c-.921.359-1.905.554-2.904.554H5.5c-3.033,0-5.5-2.467-5.5-5.5,0-2.044,1.119-3.888,2.894-4.841-.586-1.133-.894-2.388-.894-3.659,0-1,.195-1.984.553-2.904l.792.792c-.216.68-.346,1.389-.346,2.112,0,1.271.352,2.524,1.019,3.622l.303.499-.539.223c-1.69.699-2.782,2.331-2.782,4.157,0,2.481,2.019,4.5,4.5,4.5h10.5c.722,0,1.432-.129,2.113-.345Zm3.395-.854l2.46,2.46-.707.707L.031.738.738.031l3.461,3.461c1.509-1.59,3.593-2.492,5.801-2.492,3.741,0,6.965,2.591,7.792,6.208,3.617.826,6.208,4.051,6.208,7.792,0,2.21-.901,4.293-2.492,5.801ZM4.906,4.199l15.894,15.894c1.403-1.321,2.199-3.151,2.199-5.094,0-3.373-2.406-6.265-5.722-6.877l-.338-.062-.062-.338c-.613-3.315-3.506-5.722-6.878-5.722-1.941,0-3.773.796-5.094,2.199Z";
    } else if (loginError) {
        path = "m4,8h3v1.6h-1.4v1.801h1.4v1.6h-1.4v3h-1.6v-8Zm14.6,0h-1.6v8h3v-1.6h-1.4v-6.4Zm-4.6,8h1.6v-8h-1.6v8Zm-1.839-6.373l1.339,6.373h-1.632l-.295-1.4h-2.379l-.309,1.4h-1.584l1.365-6.418c.116-.58.455-1.079.926-1.356.255-.15.535-.225.816-.225.242,0,.486.056.718.168.512.246.9.791,1.035,1.459Zm-.924,3.373l-.641-3.044c-.047-.231-.144-.334-.168-.349l-.017-.006c-.071.042-.146.146-.176.296l-.686,3.104h1.688Zm12.764-7v15H0V6c0-1.654,1.346-3,3-3h18c1.654,0,3,1.346,3,3Zm-2,0c0-.552-.448-1-1-1H3c-.551,0-1,.448-1,1v13h20V6Z";
    } else if (isLoggedIn) {
        path = "m8,12c3.309,0,6-2.691,6-6S11.309,0,8,0,2,2.691,2,6s2.691,6,6,6Zm0,2C3.589,14,0,17.589,0,22v1c0,.552.448,1,1,1h14c.552,0,1-.448,1-1v-1c0-4.411-3.589-8-8-8Zm15.697-3.783l-3.992,3.875c-.581.582-1.368.907-2.205.907s-1.624-.326-2.215-.917l-1.971-1.854c-.402-.378-.421-1.011-.042-1.414.379-.402,1.011-.421,1.414-.042l1.992,1.875c.471.47,1.195.449,1.623.021l4.002-3.886c.396-.385,1.029-.375,1.414.021.385.396.375,1.03-.021,1.414Z";
    } else {
        path = "m3 6a6 6 0 1 1 6 6 6.006 6.006 0 0 1 -6-6zm6 8a9.01 9.01 0 0 0 -9 9 1 1 0 0 0 1 1h16a1 1 0 0 0 1-1 9.01 9.01 0 0 0 -9-9zm12.414-2 2.293-2.293a1 1 0 0 0 -1.414-1.414l-2.293 2.293-2.293-2.293a1 1 0 0 0 -1.414 1.414l2.293 2.293-2.293 2.293a1 1 0 1 0 1.414 1.414l2.293-2.293 2.293 2.293a1 1 0 0 0 1.414-1.414z";
    }

    return (
        <StyledAction onClick={handleIconClick} {...otherProps}>
            <Box position="relative" display="flex" alignItems="center">
                <SvgIcon viewBox={viewBox} fontSize="small" className={highlight ? 'degradation-alert' : ''}>
                    <path d={path}/>
                </SvgIcon>
                {loginError && (
                    <SvgIcon>
                        <path
                            d="M6.41,9H17.59a1,1,0,0,1,.7,1.71l-5.58,5.58a1,1,0,0,1-1.42,0L5.71,10.71A1,1,0,0,1,6.41,9Z"/>
                    </SvgIcon>
                )}
                {showError && (
                    <Box position="absolute" top="100%" left="0" bgcolor="white" boxShadow={2} p={2} zIndex="tooltip"
                         width="200px">
                        <Typography variant="body2" color="error">
                            {loginError?.title}
                            {loginError?.innerError?.title}
                            {loginError?.innerError?.details}
                        </Typography>
                    </Box>
                )}
            </Box>
        </StyledAction>
    );
}

export default AppStateIcon;