import {Box, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import SimpleVerseComponent from "./SimpleVerseComponent";
import VerseTitleComponent from "./VerseTitleComponent";
import './VerseComponent.css';
import VerseActionsComponent from "./VerseActionsComponent";
import VerseLayout, {LayoutStyle} from "./VerseLayout";
import BibleService from "../../services/BibleService";
import ScriptureContext from "../../model/ScriptureContext";
import ScriptureRef from "../../model/ScriptureRef";
import Clipboard from "../../services/Clipboard";
import StyledCard from "../../styles/StyledCard";
import StyledCardContent from "../../styles/StyledCardContent";
import ScriptureAtom from "../../model/Biblical/ScriptureAtom";
import {usePosts} from "../../contexts/PostsContext";
import {SemanticRef} from "../../model/SemWeb/SemanticRef";
import {RelatedAtom} from "../../model/Core/RelatedAtom";
import {useAppState} from "../../contexts/AppStateContext";
import RelatedEditorComponent from "../related/RelatedEditorComponent";
import InterlinearDisplayComponent from "./InterlinearComponent";
import Atom from "../../model/Core/Atom";

export enum ShowRef {
    FULL = 1,
    LEFT = 2,
}

type Props = {
    scripture: ScriptureAtom;
    onSelectPost: (ref: SemanticRef) => void;
    onExplore?: (ref: string) => void;
    searchText?: string;
    layout: LayoutStyle;
    exploring?: boolean;
    onMeld?: (scripture: ScriptureAtom) => void;
    onClose?: (scripture: ScriptureAtom) => void;
    onAddToSession?: (scripture: Atom) => void;
};

const VerseComponent: React.FC<Props> = (props) => {
    let {scripture, searchText, exploring} = props;
    const postsContext = usePosts();
    const {isOnline, isLoggedIn, reportDegradation} = useAppState();
    const [layout, setLayout] = React.useState<VerseLayout>(VerseLayout.fromStyle(props.layout, scripture.ref.numVerses()));
    const [context, setContext] = React.useState<ScriptureContext>(new ScriptureContext());
    const [showActions, setShowActions] = React.useState(false);
    const [showPosts, setShowPosts] = React.useState(layout.expandTopics);
    const [postReferences, setPostReferences] = React.useState<RelatedAtom>(new RelatedAtom(null, null, 'none', []));
    const [showInterlinear, setShowInterlinear] = useState(false);
    const clipboard = new Clipboard();

    useEffect(() => {
        console.log("VerseComponent render");
        const ref = new SemanticRef(scripture.ref.toUri(false), 'n/a');
        if (isOnline && isLoggedIn) {
            postsContext
                .getPostsByAtomRef(ref)
                .then(x => setPostReferences(new RelatedAtom(null, null, 'posts', x)));
        } else {
            setPostReferences(new RelatedAtom(null, null, 'posts', []));
            reportDegradation('Login', 'Posts for Verse');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOnline, isLoggedIn, scripture.ref]);

    const doClose = (scripture: ScriptureAtom) => {
        setShowPosts(false);
        setShowInterlinear(false);
        setLayout(VerseLayout.fromStyle(LayoutStyle.Reader));
        if (props.onClose !== undefined) {
            props.onClose(scripture);
        }
    };

    const doCopy = async () => {
        let text = scripture.text;
        let fromVerse = scripture.ref.from;
        let toVerse = scripture.ref.to;
        if (context.before !== undefined) {
            text = context.before.text + " " + text;
            fromVerse = context.before.ref.from;
        }
        if (context.after !== undefined) {
            text = text + " " + context.after.text;
            toVerse = context.after.ref.to;
        }
        const ref = new ScriptureRef(scripture.ref.bookName, scripture.ref.chapter, fromVerse, toVerse);
        text = text.replaceAll("{woj}", "").replaceAll("{endwoj}", "");
        await clipboard.writeText(`"${text}" - ${ref.toString()}`);
        return ref.toString();
    }

    const addAtomToPost = async (postRef: SemanticRef) => {
        await postsContext.addAtomToPost(scripture, postRef);
    }

    const doExplore = () => {
        setLayout(VerseLayout.fromStyle(LayoutStyle.Explore));
        if (props.onExplore !== undefined) {
            props.onExplore(scripture.ref.toString());
        }
    };

    const doToggleContext = async () => {
        if (context.before !== undefined || context.after !== undefined) {
            setContext(new ScriptureContext());
        } else {
            setContext(await BibleService.expandContext(scripture));
        }
    };

    const toggleInterlinear = () => {
        setShowInterlinear(!showInterlinear);
    };

    return (
        <>
            {layout.showTitle && <VerseTitleComponent title={scripture.title}/>}
            <Box display="flex" flexDirection="row" width='100%'>
                {(layout.showRef === ShowRef.LEFT) &&
                    <Box className={`verse-left${postReferences.refs.length > 0 ? " goodies" : ""}`}
                         onClick={doExplore}>
                        {scripture.ref?.from}
                    </Box>}
                <Box flex={1} flexDirection="column">
                    {layout.showCard ?
                        <>
                            <StyledCard key={scripture.ref?.toString()}
                                        onMouseEnter={() => setShowActions(true)}
                                        onMouseLeave={() => setShowActions(false)}>
                                <StyledCardContent
                                    onTouchStart={() => setShowActions(true)}
                                    onTouchEnd={() => setShowActions(false)}>
                                    {context.before &&
                                        <SimpleVerseComponent scripture={context.before} searchText={searchText}
                                                              className="verse-before" showFirstVerseNo={true}/>}
                                    <SimpleVerseComponent scripture={scripture} searchText={searchText}
                                                          showFirstVerseNo={context.hasContext}
                                                          className={(context.hasContext) ? "verse-focus" : ""}/>
                                    {context.after &&
                                        <SimpleVerseComponent scripture={context.after} searchText={searchText}
                                                              className="verse-after" showFirstVerseNo={true}/>}
                                </StyledCardContent>
                                <VerseActionsComponent
                                    scripture={scripture} canClose={layout.canClose}
                                    canExpandContext={layout.canExpandContext}
                                    showActions={exploring || showActions} postsCount={postReferences.refs.length}
                                    onClose={() => doClose(scripture)} onCopy={doCopy} onMeld={props.onMeld}
                                    onNotesClick={() => setShowPosts(!showPosts)} onStrongsClick={toggleInterlinear}
                                    onExpandContext={doToggleContext} isExpandedContext={context.hasContext}
                                    onAddToNotes={props.onAddToSession} searchText={searchText}
                                />
                            </StyledCard>
                        </>
                        :
                        <Box display="flex" flexDirection="column">
                            <SimpleVerseComponent scripture={scripture} searchText={searchText}/>
                            {(layout.showRef === ShowRef.FULL) && (
                                <div className="verse-reference">
                                    <Typography variant="body1" color="text.secondary" textAlign="right">
                                        {scripture.ref.toString()}
                                    </Typography>
                                </div>
                            )}
                        </Box>
                    }
                </Box>
            </Box>
            <div style={{display: 'flex', alignItems: 'stretch'}}>
                <div style={{
                    display: 'flex', // Make this a flex container
                    flexDirection: 'column', // Stack children vertically
                    justifyContent: 'space-between', // Push the remove icon to the bottom
                    alignItems: 'center', // Center items horizontally
                    width: '10px',
                    marginRight: '10px',
                    backgroundColor: '#3F5280'
                }}>
                    <div style={{width: '10px', height: '100%', cursor: 'grab'}}></div>
                    {/*<MiniMenuIcon onClick={handleClick}/>*/}
                </div>
                <div style={{flexGrow: 1}}>
                    {showPosts && <RelatedEditorComponent
                        atom={postReferences}
                        canRemove={false}
                        onSelect={props.onSelectPost}
                        onAdd={addAtomToPost}
                    />}
                    {showInterlinear && <InterlinearDisplayComponent
                        scripture={scripture}
                        onAddToNotes={props.onAddToSession}/>}
                </div>
            </div>
        </>
    );
};

const MemoizedVerseComponent = React.memo(VerseComponent);

export default MemoizedVerseComponent;