export class StrongsEntry {
    public id: string;
    public strongs: string;
    public strongsDef: string;
    public derivation: string;
    public translit: string;
    public lemma: string;
    public kjvDef: string;
    constructor(id: string, strongs: string, strongsDef: string, derivation: string, translit: string, lemma: string, kjvDev: string) {
        this.id = id;
        this.strongs = strongs;
        this.strongsDef = strongsDef;
        this.derivation = derivation;
        this.translit = translit;
        this.lemma = lemma;
        this.kjvDef = kjvDev;
    }

    public static fromJSON(json: any): StrongsEntry {
        return new StrongsEntry(json.id, json.strongs, json.strongsDef, json.derivation, json.translit, json.lemma, json.kjvDef);
    }
}