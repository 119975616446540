import styled from "@emotion/styled";
import {Card} from "@mui/material";

export const StrongsDefStyledCard = styled(Card)(
    ({ theme }) => ({
        marginTop: 0,
        marginBottom: '0.5rem',
        backgroundColor: '#343747',
        borderRadius: '1rem',
        padding: '0.5rem',
    })
);