import {DraggableProvidedDragHandleProps} from "@hello-pangea/dnd";
import {ReactNode} from "react";
import MiniMenuIcon from "../icons/MiniMenuIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";

interface DraggableWrapperProps {
    children: ReactNode;
    onRemove: () => void;
    onAddNote: () => void;
    onAddRelated: () => void;
    onSplit: () => void;
    dragHandleProps: DraggableProvidedDragHandleProps | null;
}

const DraggableWrapper = ({children, onRemove, onAddNote, onAddRelated, onSplit, dragHandleProps}: DraggableWrapperProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    }

    return (
        <>
            <div style={{display: 'flex', alignItems: 'stretch'}}>
                <div {...dragHandleProps} style={{
                    display: 'flex', // Make this a flex container
                    flexDirection: 'column', // Stack children vertically
                    justifyContent: 'space-between', // Push the remove icon to the bottom
                    alignItems: 'center', // Center items horizontally
                    width: '10px',
                    marginRight: '10px',
                    backgroundColor: '#3F5280'
                }}>
                    {/* This div can be used as the drag handle if needed */}
                    <div style={{width: '10px', height: '100%', cursor: 'grab'}}></div>
                    <MiniMenuIcon onClick={handleClick}/>
                </div>
                <div style={{flexGrow: 1}}>
                    {children}
                </div>
            </div>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem key="add-note" onClick={onAddNote}>Add note</MenuItem>
                <MenuItem key="add-related" onClick={onAddRelated}>Add related</MenuItem>
                <MenuItem key="split" onClick={onSplit}>Split into two topics</MenuItem>
                <MenuItem key="remove" onClick={onRemove}>Remove</MenuItem>
            </Menu>
        </>
    );
};

export default DraggableWrapper;