import Block from "./Block";

class EmbeddedBlock extends Block {
    public data: string;
    constructor(data: string) {
        super();
        this.data = data;
    }

    public getContent(): string {
        return this.data;
    }

    public setContent(content: string) {
        this.data = content;
    }

    toJSON() {
        return { ...this, type: 'Embedded' };
    }

    static fromJSON(json: any): EmbeddedBlock {
        return new EmbeddedBlock(json.data);
    }
}

export default EmbeddedBlock;