import styled from '@emotion/styled';

const StyledAction = styled.div`
  color: #b2b2b2;
  &:hover {
    color: #ddac6a;
  }
  margin-top: 0;
  margin-right: 0.25rem;
  padding: 0.15rem;
`;

export default StyledAction;