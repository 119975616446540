export class SemanticRef {
    public uri: string;
    public label: string;
    private labelLowerCase: string;

    constructor(uri: string, label: string) {
        this.uri = uri;
        this.label = label;
        this.labelLowerCase = label.toLowerCase();
    }

    public getKind(): string {
        return this.uri.split(':')[0];
    }

    public isMatch(text: string, fullMatch?: boolean ): boolean {
        text = text.toLowerCase();
        if (fullMatch) return this.labelLowerCase === text;
        return this.labelLowerCase.includes(text);
    }

    public static fromPost(id: string, title: string): SemanticRef {
        return new SemanticRef(`post:${id}`, title);
    }

    public static fromBible(book: string, chapter?: number, fromVerse?: number, toVerse?: number): SemanticRef {
        let uri = `bible:${book}`;
        let label = `${book}`;
        if (chapter) {
            uri += `/${chapter}`;
            label += ` ${chapter}`;
            if (fromVerse) {
                uri += `/${fromVerse}`;
                label += ` ${fromVerse}`;
                if (toVerse && !(toVerse === fromVerse)) {
                    uri += `-${toVerse}`;
                    label += `-${toVerse}`;
                }
            }
        }
        return new SemanticRef(uri, label);
    }

    public static fromSearch(text: string | null): SemanticRef {
        const encodedText = encodeURIComponent(text ?? "");
        return new SemanticRef(`search:${encodedText}`, text ?? "");
    }

    public static fromStrongs(strongs: string | null): SemanticRef {
        return new SemanticRef(`strongs:${strongs}`, strongs ?? "");
    }

    public isPost(): boolean {
        return this.uri.startsWith("post:");
    }

    public static fromJSON(json: any): SemanticRef {
        return new SemanticRef(json.uri, json.label);
    }

    public getId(): string | undefined {
        const segments = this.uri.split(':').filter(Boolean);
        return segments.pop();
    }
}