import {createTheme} from '@mui/material/styles';
import {PaletteColor, PaletteColorOptions} from "@mui/material";

declare module '@mui/material/styles' {
    interface Palette {
        actionBackgroundColor: PaletteColor;
    }
    interface PaletteOptions {
        actionBackgroundColor?: PaletteColorOptions;
    }
}

const defaultTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#ddac6a'
        },
        secondary: {
            main: '#9490a0'
        },
        background: {
            default: '#2a2e39'
        },
        actionBackgroundColor: {
            main: '#383c47'
        }
    },
    spacing: 3,
    components: {
        MuiIconButton: {
            styleOverrides: {
                root: {
                    marginLeft: 0
                }
            }
        }
    }
});

export default defaultTheme;