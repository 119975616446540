import ScriptureRef from "./ScriptureRef";
import BibleService from "../services/BibleService";
import ScriptureAtom from "./Biblical/ScriptureAtom";

class LazyScripture {
    private readonly reference: string;
    private scripturePromise: Promise<ScriptureAtom> | null = null;

    public static fromReference(reference: string) {
        return new LazyScripture(reference);
    }

    public static fromScripture(scripture: ScriptureAtom) {
        return new LazyScripture(scripture.ref.toString(), scripture);
    }

    private constructor(reference: string, scripture?: ScriptureAtom) {
        this.reference = reference ?? scripture?.ref.toString();
        if (scripture !== undefined) {
            this.scripturePromise = Promise.resolve(scripture);
        }
    }

    public getValue(): Promise<ScriptureAtom> {
        if (!this.scripturePromise) {
            this.scripturePromise = this.loadScripture(this.reference);
        }

        return this.scripturePromise;
    }

    private async loadScripture(reference: string): Promise<ScriptureAtom> {
        const scriptureRef = ScriptureRef.parse(reference);
        return await BibleService.getMergedScripture(scriptureRef);
    }
}

export default LazyScripture;