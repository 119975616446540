import React, { useEffect, useState, useMemo } from 'react';
import { debounce } from '@mui/material';
import { usePosts } from '../../contexts/PostsContext';
import { useAppState } from '../../contexts/AppStateContext';
import BibleService from '../../services/BibleService';
import SearchService from '../../services/Search/SearchService';
import { SemanticRef } from '../../model/SemWeb/SemanticRef';
import StyledAutocomplete from '../../styles/StyledAutocomplete';
import StyledAutocompleteTextField from '../../styles/StyledAutocompleteTextField';

type Props = {
    searchPosts: boolean,
    searchBible: boolean,
    searchBibleBooks: boolean,
    onSelect: (value: SemanticRef) => void;
};

const SearchComponent = (props: Props) => {
    const postsContext = usePosts();
    const { isLoggedIn, reportDegradation } = useAppState();
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState<SemanticRef[]>([]);

    const debouncedSearch = useMemo(
        () =>
            debounce(async (query: string) => {
                const searchPromises = [];
                if (props.searchPosts && isLoggedIn && query.length >= 3) {
                    searchPromises.push((async () => {
                        try {
                            return await postsContext.searchPosts(query);
                        } catch (error) {
                            return [];
                        }
                    })());
                } else if (props.searchPosts) {
                    reportDegradation('Login', 'Search Posts');
                }

                if (props.searchBibleBooks && query.length >= 0) {
                    searchPromises.push((async () => {
                        try {
                            return await BibleService.searchBookNames(query);
                        } catch (error) {
                            return [];
                        }
                    })());
                }

                if (props.searchBible && query.length >= 3) {
                    searchPromises.push((async () => {
                        try {
                            return SearchService.autoComplete(query);
                        } catch (error) {
                            return [];
                        }
                    })());
                }

                const results = await Promise.all(searchPromises);
                const combinedResults = results.flat();
                setSearchResults(combinedResults);
            }, 300),
         // eslint-disable-next-line react-hooks/exhaustive-deps
        [isLoggedIn]
    );

    useEffect(() => { debouncedSearch(searchTerm); }, [searchTerm, debouncedSearch]);

    const handleSearchChange = (event: React.ChangeEvent<{}>, value: string | SemanticRef | null) => {
        if (typeof value === 'string') {
            setSearchTerm(value);
        }
    };

    const handleOptionSelected = async (_event: React.ChangeEvent<{}>, value: SemanticRef | string | null) => {
        // Check if the value is a string and try to find a matching SemanticRef in the searchResults
        if (typeof value === 'string') {
            let ref: SemanticRef | undefined;
            // Scripture
            ref = await BibleService.tryParse(value);
            // Posts
            if (!ref) ref = searchResults.find(option => option.isPost() && option.isMatch(value, true));
            // Search
            if (!ref) ref = SemanticRef.fromSearch(value);
            props.onSelect(ref);
        } else if (value instanceof SemanticRef) {
            props.onSelect(value);
        }
    };

    const getOptionLabel = (option: SemanticRef | string) => {
        if (option instanceof SemanticRef) return option.label;
        return option;
    };

    return (
        <StyledAutocomplete
            freeSolo
            sx={{ width: '100%' }}
            getOptionLabel={getOptionLabel}
            onInputChange={handleSearchChange}
            onChange={handleOptionSelected}
            id="search-autocomplete"
            options={searchResults}
            clearOnEscape={true}
            selectOnFocus={true}
            clearOnBlur={true}
            renderInput={(params) => (
                <StyledAutocompleteTextField {...params} label="" fullWidth InputProps={{ ...params.InputProps }} />
            )}
        />
    );
};

export default SearchComponent;
