import React, {createContext, useContext} from 'react';
import {SemanticRef} from "../model/SemWeb/SemanticRef";
import PostService from "../services/PostService";
import {useAuth0} from "@auth0/auth0-react";
import PostMolecule from "../model/Core/PostMolecule";
import {CacheService} from "../services/CacheService";
import {ServerError} from "../core/errors/ServerError";
import ScriptureRef from "../model/ScriptureRef";
import {SemanticRelation} from "../model/SemWeb/SemanticRelation";
import {PagedResult} from "../core/PagedResult";
import Atom from "../model/Core/Atom";
import ScriptureAtom from "../model/Biblical/ScriptureAtom";
import {BadRequestError} from "../core/errors/BadRequestError";

interface PostsProps {
    children: React.ReactNode;
}

interface PostsContextType {
    getPosts: () => Promise<SemanticRef[]>;
    searchPosts: (query: string) => Promise<SemanticRef[]>;
    getPostsByAtomRef: (ref: SemanticRef) => Promise<SemanticRef[]>;
    getPost: (id: string) => Promise<PostMolecule>;
    putPost: (post: PostMolecule) => Promise<PostMolecule>;
    resetCache: (uriPrefix: string) => Promise<void>;
    addAtomToPost: (atom: Atom, postRef: SemanticRef) => Promise<void>;
}

const PostsContext = createContext<PostsContextType>({
    getPosts: () => Promise.resolve([]),
    searchPosts: (query: string) => Promise.resolve([]),
    getPostsByAtomRef: () => Promise.resolve([]),
    getPost: (id: string) => Promise.resolve(new PostMolecule(null, null, [], "empty")),
    putPost: (post: PostMolecule) => Promise.resolve(new PostMolecule(null, null, [], "empty")),
    addAtomToPost: (atom: Atom, postRef: SemanticRef) => Promise.resolve(),
    resetCache: (uriPrefix: string) => Promise.resolve()
});

export const usePosts = () => useContext(PostsContext);

export const PostsProvider = ({ children }: PostsProps) => {
    let lastLogTime: number | null = null;
    const logInterval = 120000; // 2 minutes in milliseconds

    const { getAccessTokenSilently } = useAuth0();
    const cache = new CacheService<PagedResult<SemanticRelation>>();

    const getAccessToken = async (required: boolean): Promise<string | undefined> => {
        try {
            return await getAccessTokenSilently();
        } catch (error) {
            const currentTime = new Date().getTime(); // Get current time in milliseconds
            if (required) throw error;
            if (lastLogTime === null || currentTime - lastLogTime >= logInterval) {
                console.log(JSON.stringify(ServerError.toIError(error).getErrorBody()));
                lastLogTime = currentTime;
            }
        }
    }

    const postsService = new PostService(getAccessToken);

    const searchPosts = async (query: string): Promise<SemanticRef[]> => {
        return (await postsService.searchPosts(query)).items;
    }

    const getPosts = async (): Promise<SemanticRef[]> => {
        return (await postsService.getRefs()).items;
    };

    const getPostsByAtomRef = async (verseRef: SemanticRef): Promise<SemanticRef[]> => {
        const scripture = ScriptureRef.fromUri(verseRef.uri);
        const chapterRef = SemanticRef.fromBible(scripture.bookName, scripture.chapter);
        const relations = await getPostsByAtomRefChapter(chapterRef);
        const refs = relations.items
            .filter(x => ScriptureRef.fromUri(x.to.uri).isMatch(scripture))
            .map(x => x.from);

        return Array
            .from(new Set(refs.map(ref => ref.uri)))
            .map(uri => {
                return refs.find(ref => ref.uri === uri)!;
            });
    }

    const getPostsByAtomRefChapter = async (chapterRef: SemanticRef): Promise<PagedResult<SemanticRelation>> => {
        try {
            let promise = cache.get(chapterRef.uri);
            if (promise === undefined) {
                promise = postsService.getRefsByAtomRef(chapterRef);
                cache.set(chapterRef.uri, promise);
            }
            return promise!;
        }
        catch (error) {
            throw ServerError.toIError(error);
        }
    }

    const resetCache = async (uriPrefix: string): Promise<void> => {
        console.log(`reset cache "${uriPrefix}"`);
        cache.reset(uriPrefix);
    }

    const getPost = async (id: string): Promise<PostMolecule> => {
        return await postsService.getPost(id);
    };

    const putPost = async (post: PostMolecule): Promise<PostMolecule> => {
        const result = await postsService.putPost(post);
        const scriptureAtoms = post.atoms.filter(atom => atom instanceof ScriptureAtom) as ScriptureAtom[];
        scriptureAtoms.forEach(a => resetCache(a.ref.toUri(true)));
        return result;
    };

    const addAtomToPost = async (atom: Atom, postRef: SemanticRef): Promise<void> => {
        const postId = postRef.getId();
        if (postId === undefined) {
            throw new BadRequestError("Unable to add Atom to Post", `${postRef.uri} does not refer to a valid post.`);
        }
        const post = await postsService.getPost(postId);
        post.atoms.push(atom);
        await postsService.putPost(post);
    };

    return (
        <PostsContext.Provider value={{ getPosts, searchPosts, getPostsByAtomRef, getPost, putPost, resetCache, addAtomToPost }}>
            {children}
        </PostsContext.Provider>
    );
};