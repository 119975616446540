import EmbeddedBlock from "./EmbeddedBlock";
import Block from "./Block";
import LinkedBlock from "./LinkedBlock";

export default class BlockDeserializer {
    static deserialize(json: any): Block {
        switch (json.type) {
            case 'Embedded':
                return EmbeddedBlock.fromJSON(json);
            case 'Linked':
                return LinkedBlock.fromJSON(json);
            default:
                throw new Error(`Unknown block type: ${json.type}`);
        }
    }
}