import Atom from "../Core/Atom";
import ScriptureRef from "../ScriptureRef";

class ScriptureAtom extends Atom {
    ref: ScriptureRef;
    context?: ScriptureRef;
    title?: string;
    text: string;

    constructor(id: string | null, etag: string | null, refStr: string, text: string, topics: string[], notes?: string, title?: string, context?: string) {
        const temp = ScriptureRef.tryParse(refStr);
        if (temp === undefined) {
            throw Error(`Invalid scripture reference, '${refStr}'`);
        }
        super(id, etag);
        this.ref = temp;
        this.context = context === undefined ? undefined : ScriptureRef.tryParse(context);
        this.text = text;
        this.title = title;
    }

    public static merge(scriptures: ScriptureAtom[]): ScriptureAtom {
        if (scriptures.length === 0) {
            throw new Error("Array must contain at least one scripture.")
        }
        const mergedText = scriptures.map(s => s.text).join(" ");
        const mergedRef = new ScriptureRef(scriptures[0].ref.bookName, scriptures[0].ref.chapter, scriptures[0].ref.from, scriptures[scriptures.length - 1].ref.to)
        return new ScriptureAtom(scriptures[0].id, scriptures[0].etag, mergedRef.toString(), mergedText, []);
    }

    override getWordCount(): number {
        const words = this.text.split(/\s+/).filter(word => word.length > 0);
        return words.length;
    }

    override getName(): string {
        return this.ref.toString();
    }

    toJSON() {
        return {
            ...super.toJSON(),
            ref: this.ref.toUri(),
            text: this.text,
            context: this.context?.toString(),
            type: 'Scripture'
        };
    }

    static fromJSON(json: any) {
        return new ScriptureAtom(json.id, json.etag, json.ref, json.text, json.topics, json.notes, json.title, json.context);
    }
}

export default ScriptureAtom;