import styled from '@emotion/styled';
import {CardContent} from "@mui/material";

const StyledCardContent = styled(CardContent)(
    ({ theme }) => ({
        paddingTop: '0.5rem',
        paddingLeft: '0.5rem',
        paddingRight: '0.5rem',
        paddingBottom: 0,
        margin: 0
    })
);

export default StyledCardContent;