import StyledAction from "../../styles/StyledActionSvgIcon";
import { SvgIcon } from "@mui/material";
import React from "react";

type Props = {
    onClick: () => void;
    disabled?: boolean;
};

function BackIcon({ onClick, disabled = false }: Props) {
    return (
        <StyledAction>
            <SvgIcon
                viewBox="0 0 24 24"
                fontSize="small"
                onClick={!disabled ? onClick : undefined}
                className={disabled ? "disabled" : "enabled"}
            >
                <path
                    d="M17.921,1.505a1.5,1.5,0,0,1-.44,1.06L9.809,10.237a2.5,2.5,0,0,0,0,3.536l7.662,7.662a1.5,1.5,0,0,1-2.121,2.121L7.688,15.9a5.506,5.506,0,0,1,0-7.779L15.36.444a1.5,1.5,0,0,1,2.561,1.061Z" />
            </SvgIcon>
        </StyledAction>
    );
}

export default BackIcon;