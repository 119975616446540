import {IdService} from "../../services/IdService";

class Atom {
    public id: string | null;
    public etag: string | null;
    constructor(id: string | null, etag: string | null) {
        this.id = id ?? IdService.generateTempId();
        this.etag = etag;
    }

    public getWordCount(): number {
        return 0;
    }

    toJSON() {
        return { ...this, type: 'Atom' };
    }

    public getName(): string
    {
        return this.id ?? 'Unknown';
    }
}

export default Atom;