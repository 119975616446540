import {SemanticRef} from "../model/SemWeb/SemanticRef";
import {InterlinearChapter} from "../model/Biblical/Interlinear";
import ApiClient from "../core/ApiClient";

class InterlinearService {
    private readonly getAccessToken?: (required: boolean) => Promise<string | undefined>

    constructor(getAccessToken?: (required: boolean) => Promise<string | undefined>) {
        this.getAccessToken = getAccessToken;
    }

    public async getChapter(ref: SemanticRef): Promise<InterlinearChapter> {
        const client = new ApiClient((r) => Promise.resolve(undefined));
        const refValue = encodeURIComponent(ref.uri);
        return await client.getSingle<InterlinearChapter>(
            `https://rtar.org/.netlify/functions/interlinear?ref=${refValue}`,
            InterlinearChapter.fromJSON);
    }
}

export default InterlinearService;