import StyledAction from "../../styles/StyledActionSvgIcon";
import {SvgIcon} from "@mui/material";
import React from "react";
import IconProps from "./IconProps";

function MenuIcon({ onClick, ...otherProps}: IconProps) {
    return (
        <StyledAction onClick={(e) => onClick && onClick(e)} {...otherProps}>
            <SvgIcon viewBox="0 0 24 24" sx={{width: 16, height: 16}} fontSize="small">
                <path
                    d="m12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,22c-5.514,0-10-4.486-10-10S6.486,2,12,2s10,4.486,10,10-4.486,10-10,10Zm-4-10c0,.828-.672,1.5-1.5,1.5s-1.5-.672-1.5-1.5.672-1.5,1.5-1.5,1.5.672,1.5,1.5Zm11,0c0,.828-.672,1.5-1.5,1.5s-1.5-.672-1.5-1.5.672-1.5,1.5-1.5,1.5.672,1.5,1.5Zm-5.5,0c0,.828-.672,1.5-1.5,1.5s-1.5-.672-1.5-1.5.672-1.5,1.5-1.5,1.5.672,1.5,1.5Z" />
            </SvgIcon>
        </StyledAction>
    );
}

export default MenuIcon;