import styled from "@emotion/styled";

const StyledContentHeader = styled.div`
  width: 100%;
  text-align: center;
  position: sticky;
  top:0;
  background-color: #2a2e39;
  color: #ddac6a;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

export default StyledContentHeader;