import React, {useEffect, useState} from 'react';
import {DragDropContext, Droppable, Draggable, DropResult} from "@hello-pangea/dnd";
import VerseComponent from '../components/bible/VerseComponent';
import {APP_NAME} from "../constants";
import {LayoutStyle} from "../components/bible/VerseLayout";
import StyledContentContainer from "../styles/StyledContentContainer";
import Atom from "../model/Core/Atom";
import ScriptureAtom from "../model/Biblical/ScriptureAtom";
import MarkdownAtom from "../model/Core/MarkdownAtom";
import EmbeddedBlock from "../model/Core/EmbeddedBlock";
import NotesActionsComponent from "../components/notes/NotesActionsComponent";
import NotesEditorComponent from "../components/notes/NotesEditorComponent";
import PostMolecule from "../model/Core/PostMolecule";
import {useSession} from "../contexts/SessionContext";
import {useMenu} from "../contexts/MenuContext";
import {RelatedAtom} from "../model/Core/RelatedAtom";
import RelatedEditorComponent from '../components/related/RelatedEditorComponent';
import DraggableWrapper from "../components/layout/DraggableWrapper";
import MenuItem from "@mui/material/MenuItem";
import {useAuth0} from "@auth0/auth0-react";
import {useSnackbar} from "../contexts/SnackbarContext";
import {usePosts} from "../contexts/PostsContext";
import {EditableTitleComponent} from "../components/notes/EditableTitleComponent";
import StyledContentHeaderEditor from "../styles/StyledContentHeader";
import {SemanticRef} from "../model/SemWeb/SemanticRef";
import BibleService from "../services/BibleService";
import ScriptureRef from "../model/ScriptureRef";
import StrongsAtom from "../model/Biblical/StrongsAtom";
import StrongsNavigatorComponent from "../components/bible/StrongsNavigatorComponent";

interface Props {
    onSelectPost: (ref: SemanticRef) => void;
}

const PostEditorPage: React.FC<Props> = ({onSelectPost}) => {
    const session = useSession();
    const [atoms, setAtoms] = useState<Atom[]>();
    const [post, setPost] = useState<PostMolecule>();
    const [title, setTitle] = useState<string>(session.getCurrentPost().title);
    const [wordCount, setWordCount] = useState<number>(0);
    const {getAccessTokenSilently} = useAuth0();
    const postsContext = usePosts();
    const {setMenuItems} = useMenu();
    const {showMessage} = useSnackbar();

    const updateWordCount = () => {
        const wordCount = post?.getWordCount() ?? 0;
        setWordCount(wordCount);
    }

    useEffect(() => {
        setPost(session.getCurrentPost());
        setAtoms(post?.atoms);
        updateWordCount();

        const onSave = async () => {
            const updated = await postsContext.putPost(post!);
            post!.id = updated.id;
            post!.audit = updated.audit;
            post!.etag = updated.etag;
            session.saveToLocal();
            showMessage(`Saved ${post!.title}`);
        }

        const onNew = async () => {
            const newPost = session.newPost();
            setPost(newPost);
            setAtoms(newPost.atoms);
            setTitle(newPost.title);
            setWordCount(0);
            showMessage(`New ${newPost.title}`);
        }

        document.title = `${APP_NAME} - ${post?.title}`;
        setMenuItems([
            <MenuItem key="new" onClick={() => onNew()}>New</MenuItem>,
            <MenuItem key="save" onClick={() => onSave()}>Save</MenuItem>,
            // <DownloadPost menuItemKey="download-json" name="Download JSON" post={post!}
            //               onFormat={p => PostJsonExporter.export(p)}
            //               onSuccess={(filename) => showMessage(`JSON downloaded to ${filename}.`)} />,
            // <DownloadPost menuItemKey="download-markdown" name="Download Markdown" post={post!}
            //               onFormat={p => PostMarkdownExporter.export(p)}
            //               onSuccess={(filename) => showMessage(`Markdown downloaded to ${filename}.`)} />
        ]);

        // Clean up menu items when the component unmounts
        return () => setMenuItems([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postsContext, session, showMessage, setMenuItems, post, getAccessTokenSilently]);

    const onAddNote = (after?: Atom) => {
        const newAtom = new MarkdownAtom(null, null, new EmbeddedBlock("Notes..."));
        if (after) {
            post!.insertAfter(after, newAtom);
        } else {
            post!.push(newAtom);
        }
        session.saveToLocal();
        setAtoms([...post!.atoms]);
    }

    const onAddRelated = (after?: Atom) => {
        const newAtom = new RelatedAtom(null, null, "Related", []);
        if (after) {
            post!.insertAfter(after, newAtom);
        } else {
            post!.push(newAtom);
        }
        session.saveToLocal();
        setAtoms([...post!.atoms]);
    }

    const onRemoveAtom = (atom: Atom) => {
        post!.remove(atom);
        session.saveToLocal();
        setAtoms(post!.atoms);
        updateWordCount();
    }

    const onUpdateNote = (atom: Atom, note: string) => {
        (atom as MarkdownAtom).setContent(note);
        session.saveToLocal();
        updateWordCount();
    }

    const onAddToSession = async (atom: Atom): Promise<void> => {
        await session.addAtom(atom);
        showMessage(`${atom.getName()} added to notes.`);
    }

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }
        session.saveToLocal();
        setAtoms(post!.move(result.source.index, result.destination.index));
    }

    const onTitleChange = (newValue?: string) => {
        setTitle(newValue ?? "Loading...");
        if (post !== undefined && newValue !== undefined) post!.title = newValue;
        session.saveToLocal();
    };

    const onProcessText = async (after: Atom, text: string): Promise<void> => {
        const parsedRefs = await BibleService.parseScriptureReferences(text);
        // Fetch scripture content for each reference
        const scripturePromises = parsedRefs.map(ref => BibleService.getMergedScripture(ScriptureRef.fromUri(ref.uri)));
        const scriptures = await Promise.all(scripturePromises);

        // Insert fetched scriptures after the specified atom
        let lastAtom = after;
        scriptures.forEach(scripture => {
            const match = post!.atoms.find(x => x instanceof ScriptureAtom && x.ref.isMatch(scripture.ref));
            if (match === undefined) {
                post!.insertAfter(lastAtom, scripture);
                lastAtom = scripture; // Update lastAtom for the next insertion
            }
        });

        session.saveToLocal();
        setAtoms([...post!.atoms]); // Ensure this reflects the updated state
        updateWordCount();
    };

    const onSplit = async (at: Atom): Promise<void> => {
        session.saveToLocal();
        const secondPost = post!.splitAt(at);
        // second post
        const secondUpdate = await postsContext.putPost(secondPost);
        secondPost!.id = secondUpdate.id;
        secondPost!.audit = secondUpdate.audit;
        secondPost!.etag = secondUpdate.etag;
        // first post
        setAtoms([...post!.atoms]);
        const updated = await postsContext.putPost(post!);
        post!.id = updated.id;
        post!.audit = updated.audit;
        post!.etag = updated.etag;
        session.saveToLocal();
        showMessage(`Split to ${post!.title} & ${secondPost.title}`);
    }

    return (
        <>
            <StyledContentHeaderEditor>
                <EditableTitleComponent currentTitle={title} onChange={onTitleChange}/>
            </StyledContentHeaderEditor>
            <StyledContentContainer>
                {atoms ? (
                    <>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        {atoms.map((a, index) => (
                                            <Draggable key={a.id} draggableId={a.id!} index={index}>
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}>
                                                        <DraggableWrapper
                                                            dragHandleProps={provided.dragHandleProps}
                                                            onAddNote={() => onAddNote(a)}
                                                            onAddRelated={() => onAddRelated(a)}
                                                            onSplit={() => onSplit(a)}
                                                            onRemove={() => onRemoveAtom(a)}
                                                        >
                                                            {(() => {
                                                                if (a instanceof ScriptureAtom) {
                                                                    return (
                                                                        <VerseComponent
                                                                            key={index}
                                                                            scripture={a}
                                                                            onSelectPost={onSelectPost}
                                                                            layout={LayoutStyle.Editing}
                                                                        />
                                                                    );
                                                                } else if (a instanceof MarkdownAtom) {
                                                                    const note = (a.block as EmbeddedBlock).data;
                                                                    return <NotesEditorComponent
                                                                        key={index}
                                                                        value={note}
                                                                        onProcessText={(t) => onProcessText(a, t)}
                                                                        onValueChangedWithDebounce={(n) => onUpdateNote(a, n ?? "")}
                                                                    />;
                                                                } else if (a instanceof RelatedAtom) {
                                                                    return <RelatedEditorComponent
                                                                        key={index} atom={a}
                                                                        onSelect={() => session.saveToLocal()}
                                                                        canRemove={true}
                                                                        onAdd={() => {}}
                                                                    />
                                                                } else if (a instanceof StrongsAtom) {
                                                                    return <StrongsNavigatorComponent key={index} atom={a} onAddToNotes={onAddToSession} />
                                                                }
                                                                return null;
                                                            })()}
                                                        </DraggableWrapper>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </>
                ) : null}
                {((atoms?.length ?? 0) > 0) ? null : (
                    <NotesActionsComponent onAddNote={() => onAddNote()} onAddRelated={() => onAddRelated()}/>
                )}
            </StyledContentContainer>
            <StyledContentContainer>Word Count: {wordCount}</StyledContentContainer>
        </>
    );
};
export default PostEditorPage;