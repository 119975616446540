import Molecule from "./Molecule";
import Atom from "./Atom";
import AuditInfo from "./AuditInfo";
import AtomDeserializer from "./AtomDeserializer";
import IGetSemanticRef from "../SemWeb/IGetSemanticRef";
import {SemanticRef} from "../SemWeb/SemanticRef";
import {RelatedAtom} from "./RelatedAtom";

class PostMolecule extends Molecule implements IGetSemanticRef {
    public title: string;
    public audit?: AuditInfo;
    constructor(id: string | null, etag: string | null, atoms: Atom[], title: string, audit?: AuditInfo) {
        super(id, etag, atoms);
        this.title = title;
        this.audit = audit;
    }

    getSemanticRef(): SemanticRef {
        return SemanticRef.fromPost(this.id!, this.title);
    }

    public override splitAt(atom: Atom): PostMolecule {
        const second = super.splitAt(atom);
        const secondTitle = `${this.title} - 2`;
        this.title = `${this.title} - 1`;
        const secondPost = new PostMolecule(null, null, second.atoms, secondTitle);
        // links
        const linkToSecond = new RelatedAtom(null, null, "Continue To", [SemanticRef.fromPost(second.id!, secondTitle)]);
        this.insertAfter(this.atoms[this.atoms.length - 1], linkToSecond);
        const linkToFirst = new RelatedAtom(null, null, "Back To", [SemanticRef.fromPost(this.id!, this.title)]);
        this.insertAfter(this.atoms[0] ?? 0, linkToFirst);
        return secondPost;
    }

    toJSON() {
        return { ...this, type: 'Post' };
    }

    static fromJSON(json: any): PostMolecule {
        const atoms = AtomDeserializer.deserializeArray(json._atoms);
        return new PostMolecule(json.id, json.etag, atoms, json.title, json.relatedTopics);
    }
}

export default PostMolecule;