import Atom from "../model/Core/Atom";
import MoleculeDeserializer from "../model/Core/MoleculeDeserializer";
import PostMolecule from "../model/Core/PostMolecule";

class SessionManager {
    private notesKey : string = "context-notes";
    private currentPost: PostMolecule | undefined;

    public async addAtom(atom: Atom): Promise<void> {
        const alreadyAdded = this.getCurrentPost().hasAtom(atom.id);
        if (!alreadyAdded) {
            this.getCurrentPost().push(atom);
            this.saveToLocal();
        }
    }

    public getCurrentPost(): PostMolecule {
        if (this.currentPost === undefined) {
            this.currentPost = this.loadFromLocal();
        }
        return this.currentPost;
    }

    public newPost(): PostMolecule {
        this.currentPost = new PostMolecule(null, null,[], this.calcNewPostName());
        this.saveToLocal();
        return this.currentPost;
    }

    setPost(post: PostMolecule) {
        this.currentPost = post;
        this.saveToLocal();
    }

    public saveToLocal(): void {
        const key = this.currentPost?.id ?? this.notesKey;
        localStorage.setItem("session-current-id", key);
        const topicStr = JSON.stringify(this.currentPost);
        localStorage.setItem(key, topicStr);
    }

    private loadFromLocal(): PostMolecule {
        const key = localStorage.getItem("session-current-id") ?? this.notesKey;
        const topicStr = localStorage.getItem(key);
        if (topicStr) {
            return MoleculeDeserializer.deserialize(JSON.parse(topicStr)) as PostMolecule;
        }
        return this.newPost();
    }

    private calcNewPostName(): string {
        const date = new Date();
        const month = date.getMonth() + 1; // getMonth() returns 0-11, so add 1 to get 1-12
        const day = date.getDate();
        const year = date.getFullYear() % 100; // Get the last two digits of the year
        return `Journal ${month}/${day}/${year}`;
    }
}

export default SessionManager;