export class InterlinearChapter {
    public id: string;
    public ref: string;
    public version: string;
    public verses: InterlinearVerse[];
    constructor(id: string, ref: string, version: string, verses: InterlinearVerse[]) {
        this.id = id;
        this.ref = ref;
        this.version = version;
        this.verses = verses;
    }

    public static fromJSON(json: any): InterlinearChapter {
        const verses = json.verses.map((v: any) => InterlinearVerse.fromJSON(v));
        return new InterlinearChapter(json.id, json.ref, json.version, verses);
    }
}

export class InterlinearVerse {
    public number: number;
    public words: InterlinearWord[];
    constructor(number: number, words: InterlinearWord[]) {
        this.number = number;
        this.words = words;
    }

    public static fromJSON(json: any): InterlinearVerse {
        const words = json.words.map((w: any) => new InterlinearWord(w.number, w.strongs, w.partOfSpeach, w.parse, w.transliteration, w.hebrew, w.english, w.greek));
        return new InterlinearVerse(json.number, words);
    }
}

export class InterlinearWord {
    public number: number;
    public strongs: string;
    public partOfSpeach: string;
    public parse: string;
    public transliteration: string;
    public hebrew: string;
    public english: string;
    public greek: string;
    constructor(number: number, strongs: string, partOfSpeach: string, parse: string, transliteration: string, hebrew: string, english: string, greek: string) {
        this.number = number;
        this.strongs = strongs;
        this.partOfSpeach = partOfSpeach;
        this.parse = parse;
        this.transliteration = transliteration;
        this.hebrew = hebrew;
        this.english = english;
        this.greek = greek;
    }
}