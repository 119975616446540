import styled from '@emotion/styled';
import {CardActions} from "@mui/material";

const StyledCardActions = styled(CardActions)(
    ({ theme }) => ({
        paddingRight: '0.5rem',
        paddingLeft: '0.5rem',
        height: '2rem',
  })
);

export default StyledCardActions;