import {createContext, useContext} from 'react';
import SessionManager from "./SessionManager";

export const SessionContext = createContext<SessionManager | undefined>(undefined);

export const useSession = (): SessionManager => {
    const context = useContext(SessionContext);
    if (!context) {
        throw new Error('useEvent must be used within a SessionProvider');
    }
    return context;
};