import React from 'react';
import {Snackbar, Fade} from '@mui/material';
import {useSnackbar} from "../../contexts/SnackbarContext";

const GlobalSnackbar = () => {
    const {snackbarState, closeSnackbar} = useSnackbar();

    return (
        <Snackbar
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            autoHideDuration={1500}
            open={snackbarState.open}
            onClose={closeSnackbar}
            TransitionComponent={Fade}
            message={snackbarState.message}
        />
    );
};

export default GlobalSnackbar;
