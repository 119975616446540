import StyledAction from "../../styles/StyledActionSvgIcon";
import {SvgIcon} from "@mui/material";

type Props = {
    onClick: () => void;
}

function CloseIcon(props: Props) {
    return (
        <StyledAction>
            <SvgIcon viewBox="0 0 24 24" fontSize="small" onClick={props.onClick}>
                <path
                    d="M21,13h-4c-1.65,0-3,1.35-3,3v4c0,1.65,1.35,3,3,3h4c1.65,0,3-1.35,3-3v-4c0-1.65-1.35-3-3-3Zm1,7c0,.55-.45,1-1,1h-4c-.55,0-1-.45-1-1v-4c0-.55,.45-1,1-1h4c.55,0,1,.45,1,1v4ZM21,1h-2c-1.65,0-3,1.35-3,3v2c0,1.65,1.35,3,3,3h2c1.65,0,3-1.35,3-3v-2c0-1.65-1.35-3-3-3Zm1,5c0,.55-.45,1-1,1h-2c-.55,0-1-.45-1-1v-2c0-.55,.45-1,1-1h2c.55,0,1,.45,1,1v2Zm-10.28,12.31c.38,.4,.37,1.03-.03,1.41l-3.59,3.41c-.55,.55-1.3,.86-2.1,.86s-1.55-.31-2.12-.88L.31,19.72c-.4-.38-.42-1.01-.03-1.41s1.01-.42,1.41-.03l3.31,3.15V1c0-.55,.45-1,1-1s1,.45,1,1V21.43l3.31-3.15c.4-.38,1.03-.37,1.41,.03Z"/>
            </SvgIcon>
        </StyledAction>
    );
}

export default CloseIcon;