import PostMolecule from "./PostMolecule";
import TopicMolecule from "../Biblical/TopicMolecule";
import Molecule from "./Molecule";

export default class MoleculeDeserializer {
    static deserialize(json: any): Molecule {
        switch (json.type) {
            case 'Post':
                return PostMolecule.fromJSON(json);
            case 'Topic':
                return TopicMolecule.fromJSON(json);
            default:
                throw new Error(`Unknown molecule type: ${json.type}`);
        }
    }
}