import Block from "./Block";

class LinkedBlock extends Block {
    constructor(uri: string) {
        super();
    }

    public getContent(): string {
        throw new Error("Method not implemented.");
    }

    public setContent(content: string) {
        throw new Error("Cannot set the content of a linked block.");
    }

    toJSON() {
        return { ...this, type: 'Linked' };
    }

    static fromJSON(json: any): LinkedBlock {
        return new LinkedBlock(json.uri);
    }
}

export default LinkedBlock;