import React, {useEffect, useState} from 'react';
import ScriptureRef from '../model/ScriptureRef';
import {Grid, Typography} from '@mui/material';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {APP_NAME} from "../constants";
import MemoizedVerseComponent from "../components/bible/VerseComponent";
import {LayoutStyle} from "../components/bible/VerseLayout";
import BibleService from "../services/BibleService";
import StyledContentHeader from "../styles/StyledContentHeader";
import StyledContentContainer from "../styles/StyledContentContainer";
import ScriptureAtom from "../model/Biblical/ScriptureAtom";
import {useSession} from "../contexts/SessionContext";
import BackIcon from "../components/icons/BackIcon";
import NextIcon from "../components/icons/NextIcon";
import {useSnackbar} from "../contexts/SnackbarContext";
import {BadRequestError} from "../core/errors/BadRequestError";
import {SemanticRef} from "../model/SemWeb/SemanticRef";
import {CurieService} from "../services/CurieService";
import Atom from "../model/Core/Atom";

interface Props {
    onSelectPost: (ref: SemanticRef) => void;
}

type NavLinks = {
    currentName: string;
    prevBook?: SemanticRef;
    prevChapter?: SemanticRef;
    nextBook?: SemanticRef;
    nextChapter?: SemanticRef;
}

type PageState = {
    navLinks: NavLinks;
    verses: ScriptureAtom[];
    layout: LayoutStyle;
}

const BiblePage: React.FC<Props> = (props) => {
    const {onSelectPost} = props;
    const {book, chapter, verses} = useParams();
    const [pageState, setPageState] = useState<PageState>({
        navLinks: {currentName: "No verse selected"},
        verses: [],
        layout: LayoutStyle.Reader
    });
    const [lastMeld, setLastMeld] = useState<ScriptureAtom | undefined>(undefined);
    const [error, setError] = useState<Error | undefined>(undefined);
    const [searchText, setSearchText] = useState<string | undefined>();
    const navigate = useNavigate();
    const session = useSession();
    const snackbar = useSnackbar();
    const location = useLocation();

    if (error !== undefined) {
        throw error;
    }

    useEffect(() => {
        const getSearchTextFromUrl = () => {
            const query = new URLSearchParams(location.search);
            return query.get('search');
        };

        // Update the offset state based on the URL
        const newText = getSearchTextFromUrl() ?? undefined;
        setSearchText(newText);

        async function fetchData() {
            if (book === undefined) {
                throw new BadRequestError("Incomplete Bible Reference", "Book not specified.");
            }
            if (chapter === undefined) {
                throw new BadRequestError("Incomplete Bible Reference", "Chapter not specified.");
            }
            const sref = ScriptureRef.fromUriParams(book, chapter, verses);
            const chapterRef = ScriptureRef.fromUriParams(book, chapter);
            const navLinks = await getNavLinks(sref);
            let verseResults = await BibleService.getScriptures(chapterRef);
            let layout = LayoutStyle.Reader;
            if (verseResults.length === 0) {
                throw new Error(`The scripture reference, '${sref}', refers to scriptures that do not exist.`)
            }
            if (!sref.isWholeChapter()) {
                verseResults = meldScripturesInRange(verseResults, sref.from - 1, sref.to - 1).updatedVerses;
            }
            return {
                navLinks: navLinks,
                verses: verseResults,
                layout: layout
            };
        }

        fetchData()
            .then(ps => {
                setError(undefined);
                setPageState(ps);
            })
            .catch(error => setError(error));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [book, chapter, verses]);

    async function getNavLinks(ref: ScriptureRef): Promise<NavLinks> {
        function createLink(navRef?: ScriptureRef): SemanticRef | undefined {
            return navRef === undefined ? undefined : SemanticRef.fromBible(navRef.bookName, navRef.chapter);
        }

        document.title = `${APP_NAME} - ${ref?.toString()}`;
        return {
            currentName: ref.toString(),
            prevBook: createLink(await BibleService.previousBook(ref)),
            prevChapter: createLink(await BibleService.previousChapter(ref)),
            nextChapter: createLink(await BibleService.nextChapter(ref)),
            nextBook: createLink(await BibleService.nextBook(ref)),
        };
    }

    const doMeld = async (scripture: ScriptureAtom) => {
        const index = pageState.verses.findIndex(v => v === scripture);
        if (index !== -1 && index < pageState.verses.length - 1) {
            const changes = meldScripturesInRange(pageState.verses, index, index + 1);
            const newPageState = {
                ...pageState,
                verses: changes.updatedVerses
            };
            setLastMeld(changes.meldedScripture);
            setPageState(newPageState);
        }
    }

    const meldScripturesInRange = (verses: ScriptureAtom[], fromIndex: number, toIndex: number) => {
        if (fromIndex < 0 || toIndex >= verses.length || fromIndex > toIndex) {
            throw new BadRequestError('Invalid verse range', 'fromVerse must be greater than 0, and toVerse cannot be past the last verse.');
        }
        const scripturesToMeld = verses.slice(fromIndex, toIndex + 1);
        const meldedScripture = ScriptureAtom.merge(scripturesToMeld);

        const updatedVerses = [
            ...verses.slice(0, fromIndex),
            meldedScripture,
            ...verses.slice(toIndex + 1)
        ];

        return {
            meldedScripture,
            updatedVerses
        };
    };

    const doClose = async (scripture: ScriptureAtom) => {
        const index = pageState.verses.findIndex(v => v === scripture);
        if (index !== -1) {
            if (lastMeld === scripture) setLastMeld(undefined);
            const unmeldedScripture = await BibleService.getScriptures(scripture.ref);
            const newPageState = {
                ...pageState,
                verses: [
                    ...pageState.verses.slice(0, index),
                    ...unmeldedScripture,
                    ...pageState.verses.slice(index + 1)
                ]
            };
            setPageState(newPageState);
        }
    }

    const onAddToSession = async (atom: Atom): Promise<void> => {
        await session.addAtom(atom);
        snackbar.showMessage(`${atom.getName()} added to '${session.getCurrentPost().title}'.`);
    }

    if (pageState.verses.length === 0) {
        return null;
    }

    return (
        <>
            <StyledContentHeader style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <BackIcon onClick={() => navigate(CurieService.toFullUri(pageState.navLinks.prevBook?.uri!, false))}
                          disabled={!pageState.navLinks.prevBook}/>
                <BackIcon onClick={() => navigate(CurieService.toFullUri(pageState.navLinks.prevChapter?.uri!, false))}
                          disabled={!pageState.navLinks.prevChapter}/>
                <div style={{width: '250px', textAlign: 'center'}}>
                    <Typography variant="h5">
                        {pageState.navLinks.currentName}
                    </Typography>
                </div>
                <NextIcon onClick={() => navigate(CurieService.toFullUri(pageState.navLinks.nextChapter?.uri!, false))}
                          disabled={!pageState.navLinks.nextChapter}/>
                <NextIcon onClick={() => navigate(CurieService.toFullUri(pageState.navLinks.nextBook?.uri!, false))}
                          disabled={!pageState.navLinks.nextBook}/>
            </StyledContentHeader>
            <StyledContentContainer>
                {pageState.verses.map((scripture: ScriptureAtom, index) => (
                    <Grid item xs={12} key={scripture.ref?.toString()}>
                        <MemoizedVerseComponent key={scripture.ref?.toString()} onSelectPost={onSelectPost}
                                                onMeld={(index < pageState.verses.length) ? doMeld : undefined}
                                                exploring={scripture === lastMeld}
                                                onClose={doClose}
                                                scripture={scripture} layout={pageState.layout}
                                                onAddToSession={(s) => onAddToSession(s)}
                                                searchText={searchText}/>
                    </Grid>
                ))}
            </StyledContentContainer>
        </>
    );
}

export default BiblePage;