import {Typography} from "@mui/material";
import React from "react";
import './VerseComponent.css';
import ScriptureAtom from "../../model/Biblical/ScriptureAtom";

type Props = {
    scripture: ScriptureAtom;
    searchText?: string;
    className?: string;
    showFirstVerseNo?: boolean;
};

const SimpleVerseComponent: React.FC<Props> = (props) => {
    let {scripture, searchText, className, showFirstVerseNo} = props;
    const [formattedText, setFormattedText] = React.useState('');

    React.useEffect(() => {
        const formatVerseNumbers = (text: string) => {
            const regex = /\[(\d+)]\s{1,5}({woj})?(\S{1,32})/g;
            let firstVerse = !showFirstVerseNo;
            return text.replace(regex, (_, p1, woj, p3) => {
                if (firstVerse) {
                    firstVerse = false;
                    if (woj === undefined) {
                        return p3;
                    } else {
                        return `<span class="woj">${p3}`;
                    }
                } else if (woj === undefined) {
                    return `<span class="verse-number-and-first-word"><span class="verse-number">${p1}</span> ${p3}</span>`;
                } else {
                    return `<span class="verse-number-and-first-word woj"><span class="verse-number">${p1}</span> ${p3}</span>{woj}`;
                }
            });
        }
        function applyHighlights(text: string, searchText?: string) {
            if (searchText === undefined) {
                return text;
            }
            const words = searchText.split(" ").map(w => w.trim());
            const wordsRegex = words.join('|');
            const regex = new RegExp(`(${wordsRegex})`, 'gi');
            return text.replace(regex, `<span class="highlight">$1</span>`);
        }

        function highlightWordsOfJesus(text: string) {
            return text.replace(/{woj}/g, "<span class='woj'>").replace(/{endwoj}/g, "</span>");
        }
        let text = formatVerseNumbers(scripture.text);
        text = highlightWordsOfJesus(text);
        text = applyHighlights(text, searchText);
        setFormattedText(text);
    }, [scripture.text, showFirstVerseNo, searchText])

    return (
        <div className={className}>
            <Typography variant="body1" color="text.primary" dangerouslySetInnerHTML={{__html: formattedText}}/>
        </div>
    );
};

export default SimpleVerseComponent;