import React, {createContext, useContext} from 'react';
import {SemanticRef} from "../model/SemWeb/SemanticRef";
import {CacheService} from "../services/CacheService";
import ScriptureRef from "../model/ScriptureRef";
import {BadRequestError} from "../core/errors/BadRequestError";
import {InterlinearChapter, InterlinearVerse} from "../model/Biblical/Interlinear";
import InterlinearService from "../services/InterlinearService";
import {StrongsEntry} from "../model/Biblical/StrongsEntry";
import StrongsService from "../services/StrongsService";

interface BibleProps {
    children: React.ReactNode;
}

interface BibleContextType {
    getInterlinear: (ref: SemanticRef) => Promise<InterlinearVerse>;
    getStrongsEntry: (strongs: string) => Promise<StrongsEntry>;
}

const BibleContext = createContext<BibleContextType>({
    getInterlinear: () => Promise.resolve(new InterlinearVerse(1, [])),
    getStrongsEntry: () => Promise.resolve(new StrongsEntry("1", "000", "", "", "", "", "")),
});

export const useBible = () => useContext(BibleContext);

export const BibleProvider = ({children}: BibleProps) => {
    const interlinearCache = new CacheService<InterlinearChapter>();
    const interlinearService = new InterlinearService();
    const strongsCache = new CacheService<StrongsEntry>();
    const strongsService = new StrongsService();

    const getInterlinear = async (verseRef: SemanticRef): Promise<InterlinearVerse> => {
        const scripture = ScriptureRef.fromUri(verseRef.uri);
        const chapterRef = SemanticRef.fromBible(scripture.bookName, scripture.chapter);
        let promise = interlinearCache.get(chapterRef.uri);
        if (promise === undefined) {
            promise = interlinearService.getChapter(chapterRef);
            interlinearCache.set(chapterRef.uri, promise!);
        }
        const chapter = await promise;
        const verse = chapter.verses.find(x => x.number === scripture.from);
        if (verse === undefined) {
            throw new BadRequestError("Interlinear verse not found", `Ref: ${verseRef.uri} verse ${scripture.from} not found.`);
        }
        return verse;
    }

    const getStrongsEntry = async (strongs: string): Promise<StrongsEntry> => {
        const cacheKey = `strongs-${strongs}`;
        let promise = strongsCache.get(cacheKey);
        if (promise === undefined) {
            promise = strongsService.getEntry(strongs);
            strongsCache.set(cacheKey, promise!);
        }
        return await promise;
    }

    return (
        <BibleContext.Provider value={{getInterlinear, getStrongsEntry}}>
            {children}
        </BibleContext.Provider>
    );
}