import StrongsAtom from "../../model/Biblical/StrongsAtom";
import React from "react";
import ErrorMessageComponent from "../errors/ErrorMessageComponent";
import {Typography} from "@mui/material";
import {useBible} from "../../contexts/BibleContext";
import {StrongsEntry} from "../../model/Biblical/StrongsEntry";
import {StrongsDefStyledCard} from "../../styles/StrongsDefStyledCard";
import StyledCardActions from "../../styles/StyledCardActions";
import AddToNotesIcon from "../icons/AddNoteIcon";
import TextWithStrongNumbersComponent from "./TextWithStrongsNumbersComponent";
import CloseIcon from "../icons/CloseIcon";

type Props = {
    strongs: StrongsAtom;
    onAddToNotes?: (scripture: StrongsAtom) => void;
    onStrongsClick: (strongs: string) => void;
    onClose?: () => void;
};

const StrongsEntryComponent: React.FC<Props> = (props) => {
    let {strongs} = props;
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [entry, setEntry] = React.useState<StrongsEntry | null>();
    const [error, setError] = React.useState<Error | undefined>();
    const bible = useBible();

    React.useEffect(() => {
        bible.getStrongsEntry(strongs.strongs)
            .then(result => setEntry(result))
            .catch(error => setError(error))
            .finally(() => setIsLoading(false));
    }, [strongs.strongs, bible]);

    if (error !== undefined) {
        return <ErrorMessageComponent message={error.message}/>;
    }

    if (isLoading) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <StrongsDefStyledCard>
            <Typography variant="h6">Strongs: {entry?.strongs}</Typography>
            <div>
                <p><strong>Definition:</strong> <TextWithStrongNumbersComponent text={entry?.strongsDef} onStrongsClick={props.onStrongsClick} /></p>
                <p><strong>Derivation:</strong> <TextWithStrongNumbersComponent text={entry?.derivation} onStrongsClick={props.onStrongsClick}/></p>
                <p><strong>Transliteration:</strong> {entry?.translit}</p>
                <p><strong>Lemma:</strong> {entry?.lemma}</p>
                <p><strong>KJV Definition:</strong> {entry?.kjvDef}</p>
            </div>
            <StyledCardActions>
                {props.onClose && <CloseIcon aria-label="copy" onClick={props.onClose}/>}
                {props?.onAddToNotes && <AddToNotesIcon onClick={() => props?.onAddToNotes && props.onAddToNotes(strongs)}/>}
            </StyledCardActions>
        </StrongsDefStyledCard>
    );
};

export default StrongsEntryComponent;