import wordToCommonMapRaw from '../../data/common_words.json';
import commonWordToRefsMapRaw from '../../data/word_map.json';
import {SemanticRef} from "../../model/SemWeb/SemanticRef";
import ScriptureRef from "../../model/ScriptureRef";

type EncodedScriptureRef = number; // Encoded bible reference
type WordToCommonMap = { [key: string]: string }; // Maps words to common words
type CommonWordToRefsMap = { [key: string]: EncodedScriptureRef[] };
type RefsToCommonWordsMap = { [ref: number]: Set<string> };

class SearchService {
    private static wordToCommonMap = wordToCommonMapRaw as WordToCommonMap;
    private static commonWordToRefsMap = commonWordToRefsMapRaw as CommonWordToRefsMap;
    private static refsToCommonWordsMap: RefsToCommonWordsMap = {};

    public static async search(searchText: string, offset: number, limit: number): Promise<SemanticRef[]> {
        const words = searchText.toLowerCase().split(/\s+/); // Split query into words
        const refCountMap: { [ref: number]: number } = {};
        const refWordSetMap: { [ref: number]: Set<string> } = {}; // Map to track unique words for each reference

        // Process each word
        for (const word of words) {
            const commonWord = this.wordToCommonMap[word];
            if (commonWord && this.commonWordToRefsMap[commonWord]) {
                for (const ref of this.commonWordToRefsMap[commonWord]) {
                    if (!refWordSetMap[ref]) {
                        refWordSetMap[ref] = new Set();
                    }
                    refWordSetMap[ref].add(word); // Add word to the set for this reference
                }
            }
        }

        // Update refCountMap with the count of unique words per reference
        for (const ref in refWordSetMap) {
            refCountMap[ref] = refWordSetMap[ref].size;
        }

        // Sort the references first by count (descending) and then by reference (ascending)
        const sortedRefs = Object.keys(refCountMap)
            .map(Number)
            .sort((a, b) => refCountMap[b] - refCountMap[a] || a - b)
            .slice(offset, offset + limit); // Apply the limit

        // Fetch the bible details for each reference
        const scriptures = await Promise.all(
            sortedRefs.map(async ref => (await ScriptureRef.FromEncodedRef(ref)).toSemanticRef())
        );

        return scriptures.filter(ref => ref !== undefined);
    }

    public static autoComplete(query: string): SemanticRef[] {
        // Split the input into words and determine if the last word is incomplete
        const words = query.toLowerCase().trim().split(/\s+/);
        const lastWordIncomplete = !query.endsWith(' ');
        const completeWords = lastWordIncomplete ? words.slice(0, -1) : words;
        const incompleteWord = lastWordIncomplete ? words[words.length - 1] : '';

        // Find verses that contain all complete words
        const relevantRefs = this.findCommonRefsForWords(completeWords);

        // Gather all unique words from these verses
        const allWordsInRefs = new Set<string>();
        relevantRefs.forEach(ref => {
            this.refsToCommonWordsMap[ref]?.forEach(word => allWordsInRefs.add(word));
        });

        const baseString = completeWords.join(' ') + (completeWords.length > 0 ? ' ' : ''); // Reconstruct the base string from complete words
        return Array.from(allWordsInRefs)
            .filter(w => !lastWordIncomplete || w.startsWith(incompleteWord))
            .map(w => SemanticRef.fromSearch(baseString + w));
    }

    private static findCommonRefsForWords(words: string[]): EncodedScriptureRef[] {
        return words.length > 0
            ? words.map(word => this.findRefsForWord(word))
                .reduce((commonRefs, refsForWord, index) => {
                    return index === 0 ? refsForWord : commonRefs.filter(ref => refsForWord.includes(ref));
                }, [])
            : [];
    }

    private static findRefsForWord(word: string): EncodedScriptureRef[] {
        const commonWord = this.wordToCommonMap[word];
        return commonWord ? this.commonWordToRefsMap[commonWord] || [] : [];
    }

    public static generateReverseLookup() {
        // Iterate over each common word and its references
        for (const commonWord in this.commonWordToRefsMap) {
            const refs = this.commonWordToRefsMap[commonWord];
            refs.forEach(ref => {
                if (!this.refsToCommonWordsMap[ref]) {
                    this.refsToCommonWordsMap[ref] = new Set();
                }
                // Add the common word to the set for this reference
                this.refsToCommonWordsMap[ref].add(commonWord);
            });
        }
    }
}

SearchService.generateReverseLookup();

export default SearchService;