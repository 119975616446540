import {Typography} from "@mui/material";
import React from "react";
import LoadingIcon from "../icons/LoadingIcon";

type Props = {
    message: string;
};

const LoadingComponent: React.FC<Props> = (props: Props) => {
    return (
        <div style={{
            display:"flax",
            color: "white",
            padding: '6px',
            marginTop: 3,
            marginBottom: 3,
            borderRadius:'6px',
            alignItems: 'center' }}>
            <LoadingIcon />
            <Typography variant="body2" >
                {props.message}
            </Typography>
        </div>
    );
};

export default LoadingComponent;