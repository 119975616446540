import PostMolecule from "../model/Core/PostMolecule";
import ApiClient from "../core/ApiClient";
import {SemanticRef} from "../model/SemWeb/SemanticRef";
import {PagedResult} from "../core/PagedResult";
import {IdService} from "./IdService";
import {SemanticRelation} from "../model/SemWeb/SemanticRelation";

export default class PostService {
    private readonly getAccessToken: (required: boolean) => Promise<string | undefined>

    constructor(getAccessToken: (required: boolean) => Promise<string | undefined>) {
        this.getAccessToken = getAccessToken;
    }

    public async getRefs(): Promise<PagedResult<SemanticRef>> {
        const client = new ApiClient(this.getAccessToken);
        return await client.getList<SemanticRef>("https://rtar.org/.netlify/functions/posts", SemanticRef.fromJSON);
    }

    public async searchPosts(query: string): Promise<PagedResult<SemanticRef>> {
        const client = new ApiClient(this.getAccessToken);
        return await client.getList<SemanticRef>(`https://rtar.org/.netlify/functions/posts?query=${query}`, SemanticRef.fromJSON);
    }

    public async getRefsByAtomRef(ref: SemanticRef): Promise<PagedResult<SemanticRelation>> {
        const client = new ApiClient(this.getAccessToken);
        return await client.getList<SemanticRelation>(`https://rtar.org/.netlify/functions/posts?ref=${ref.uri}`, SemanticRelation.fromJSON);
    }

    public async getPost(id: string): Promise<PostMolecule> {
        const client = new ApiClient(this.getAccessToken);
        return await client.getSingle<PostMolecule>(`https://rtar.org/.netlify/functions/posts?id=${id}`, PostMolecule.fromJSON);
    }

    public async putPost(post: PostMolecule): Promise<PostMolecule> {
        const client = new ApiClient(this.getAccessToken);
        let result: PostMolecule | null;
        if (post.id === undefined || IdService.isTempId(post.id)) {
            result = await client.post<PostMolecule>("https://rtar.org/.netlify/functions/posts", post, PostMolecule.fromJSON);
            console.log(`POST of temp id ${post.id} set to permanent id ${result?.id} w. etag ${result?.etag}`);
            post.id = result?.id!;
        } else {
            result = await client.put<PostMolecule>("https://rtar.org/.netlify/functions/posts", post, PostMolecule.fromJSON, post.etag);
            post.etag = result?.etag ?? null;
            post.audit = result?.audit;
            console.log(`PUT of ${post.id} (new etag ${result?.etag})`);
        }
        return post;
    }
}