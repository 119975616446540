import React, {useState} from 'react';
import {Grid, Typography} from '@mui/material';
import {useParams, useNavigate, useLocation} from "react-router-dom";
import SearchService from "../services/Search/SearchService";
import {APP_NAME, PAGE_SIZE} from "../constants";
import './SearchPage.css';
import MemoizedVerseComponent from "../components/bible/VerseComponent";
import {LayoutStyle} from "../components/bible/VerseLayout";
import StyledContentHeader from "../styles/StyledContentHeader";
import StyledContentContainer from "../styles/StyledContentContainer";
import ScriptureAtom from "../model/Biblical/ScriptureAtom";
import {useSession} from "../contexts/SessionContext";
import BackIcon from "../components/icons/BackIcon";
import NextIcon from "../components/icons/NextIcon";
import {SemanticRef} from "../model/SemWeb/SemanticRef";
import BibleService from "../services/BibleService";
import ScriptureRef from "../model/ScriptureRef";

interface Props {
    onSelectPost: (ref: SemanticRef) => void;
}

const SearchPage: React.FC<Props> = (props) => {
    const {onSelectPost} = props;
    const {searchText} = useParams();
    const [currentOffset, setCurrentOffset] = React.useState<number>(0);
    const [verses, setVerses] = React.useState<ScriptureAtom[]>([]);
    const [error, setError] = useState<Error | undefined>(undefined);
    const session = useSession();
    const navigate = useNavigate();
    const location = useLocation();
    if (error !== undefined) {
        throw error;
    }

    React.useEffect(() => {
        const getOffsetFromUrl = () => {
            const query = new URLSearchParams(location.search);
            return parseInt(query.get('offset') || '0', 10);
        };

        // Update the offset state based on the URL
        const newOffset = getOffsetFromUrl();
        setCurrentOffset(newOffset);

        async function fetchVerses() {
            document.title = `${APP_NAME} - ${searchText}`;
            if (searchText === undefined) {
                throw new Error("No results found because no search term was provided.")
            }
            const refs = await SearchService.search(searchText, currentOffset, PAGE_SIZE);
            return await Promise.all(refs.map(async x => (await BibleService.getScriptures(ScriptureRef.fromUri(x.uri)))[0]));
        }

        fetchVerses()
            .then(verses => {
                setError(undefined);
                setVerses(verses);
            })
            .catch(error => setError(error));
    }, [searchText, currentOffset, location.search]);

    const updatePageInUrl = (newOffset: number) => {
        navigate(`?offset=${newOffset}`, { replace: true });
    };

    return (
        <>
            <StyledContentHeader style={{
                display: "flex",
                justifyContent: "center"
            }}>
                <BackIcon onClick={() => {
                    const newOffset = Math.max(0, currentOffset - PAGE_SIZE);
                    setCurrentOffset(newOffset);
                    updatePageInUrl(newOffset);
                }} />
                <Typography variant="h5">
                    {searchText} <span className="search-results">{verses.length} results</span>
                </Typography>
                <NextIcon onClick={() => {
                    const newOffset = currentOffset + PAGE_SIZE;
                    setCurrentOffset(newOffset);
                    updatePageInUrl(newOffset);
                }} />
            </StyledContentHeader>
            <StyledContentContainer>
                {verses.map((s) => (
                    <Grid item xs={12} key={s.id}>
                        <MemoizedVerseComponent
                            onSelectPost={onSelectPost}
                            scripture={s} layout={LayoutStyle.Search}
                            onAddToSession={(s) => session.addAtom(s)}
                            searchText={searchText}/>
                    </Grid>
                ))}
            </StyledContentContainer>
        </>
    );
}

export default SearchPage;