import React, {useState, useEffect} from 'react';
import {InterlinearVerse} from "../../model/Biblical/Interlinear";
import {useBible} from "../../contexts/BibleContext";
import {Typography} from "@mui/material";
import {StrongsWordStyledCard} from "../../styles/StrongsWordStyledCard";
import StrongsAtom from "../../model/Biblical/StrongsAtom";
import ScriptureAtom from "../../model/Biblical/ScriptureAtom";
import StrongsNavigatorComponent from "./StrongsNavigatorComponent";

type Props = {
    scripture: ScriptureAtom;
    onAddToNotes?: (scripture: StrongsAtom) => void;
}

const InterlinearDisplayComponent: React.FC<Props> = (props: Props) => {
    const [interlinearData, setInterlinearData] = useState<InterlinearVerse | null>(null);
    const [selectedStrongs, setSelectedStrongs] = useState<StrongsAtom | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const bible = useBible();

    useEffect(() => {
        console.log("Interlinear render");
        const fetchInterlinearData = async () => {
            setIsLoading(true);
            try {
                const verse = await bible.getInterlinear(props.scripture.ref.toSemanticRef());
                setInterlinearData(verse);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        };

        fetchInterlinearData();

    }, [props.scripture, bible]);

    if (isLoading) {
        return <Typography>Loading...</Typography>;
    }

    if (!interlinearData) {
        return <Typography>No interlinear data found</Typography>;
    }

    const handleStrongsClick = async (strongs: string) => {
        if (strongs === selectedStrongs?.strongs) {
            setSelectedStrongs(null);
        } else {
            setSelectedStrongs(new StrongsAtom(null, null, strongs));
        }
    };

    return (
        <>
            <div style={{display: 'flex', flexWrap: 'wrap', gap: '10px'}}>
                {interlinearData.words.map((word, index) => (
                    <StrongsWordStyledCard
                        key={index}
                        onClick={() => handleStrongsClick(word.strongs)}
                        style={{
                            cursor: 'pointer',
                            backgroundColor: word.strongs === selectedStrongs?.strongs ? '#565761' : '#343747',
                        }}
                    >
                        <div><strong>{word.greek || word.hebrew}</strong></div>
                        <div>{word.transliteration}</div>
                        <div>{word.strongs}</div>
                        <div style={{color: '#ddac6a'}}>{word.english}</div>
                    </StrongsWordStyledCard>
                ))}
            </div>
            {selectedStrongs && (<StrongsNavigatorComponent
                atom={selectedStrongs}
                onAddToNotes={props.onAddToNotes}
                onClose={() => setSelectedStrongs(null)}/>)}
        </>
    );
};

export default InterlinearDisplayComponent;