import {Typography} from "@mui/material";
import React from "react";
import ErrorIcon from "../icons/ErrorIcon";

type Props = {
    message: string;
};

const ErrorMessageComponent: React.FC<Props> = (props: Props) => {
    return (
        <div style={{
            display:"flex",
            backgroundColor:'#59191E',
            color: "white",
            padding: '6px',
            marginTop: 3,
            marginBottom: 3,
            borderRadius:'6px',
            alignItems: 'center',
            border: '1px solid red' }}>
            <ErrorIcon />
            <Typography variant="body2" >
                {props.message}
            </Typography>
        </div>
    );
};

export default ErrorMessageComponent;