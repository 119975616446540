import {Typography} from "@mui/material";
import React from "react";
import styled from "@emotion/styled";
import StyledContentContainer from "../../styles/StyledContentContainer";

const CenteredBox = styled.div`
  display: flex;
  justify-content: center;
`;

function Copyright() {
    return (
        <StyledContentContainer>
            <CenteredBox>
                <Typography variant="body2" color="text.secondary">
                    Scripture taken from the New King James Version®, Copyright© 1982, Thomas Nelson. All rights
                    reserved. |
                    Copyright© {new Date().getFullYear()} Troy A. Dalldorf All Rights Reserved
                </Typography>
            </CenteredBox>
        </StyledContentContainer>
    );
}

export default Copyright;