import styled from '@emotion/styled';
import {Card} from "@mui/material";

const StyledCard = styled(Card)(
    ({ theme }) => ({
        marginBottom: '0.5rem',
        marginTop: 0,
        backgroundColor: '#343747',
        borderRadius: '1rem',
    })
);

export default StyledCard;