import {SemanticRef} from "./SemanticRef";

export class SemanticRelation {
    public from: SemanticRef;
    public to: SemanticRef;
    public type: string;
    constructor(from: SemanticRef, to: SemanticRef, type: string) {
        this.from = from;
        this.to = to;
        this.type = type;
    }

    public static fromJSON(json: any): SemanticRelation {
        return new SemanticRelation(
            SemanticRef.fromJSON(json.from),
            SemanticRef.fromJSON(json.to),
            json.type
        );
    }
}