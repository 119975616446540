import StyledAction from "../../styles/StyledActionSvgIcon";
import {SvgIcon} from "@mui/material";
import React from "react";

type Props = {
    onClick: () => void;
}

function StrongsIcon(props: Props) {
    return (
        <StyledAction>
            <SvgIcon viewBox="0 0 24 24" fontSize="small" onClick={props.onClick}>
                <path
                    d="m24,23c0,.553-.447,1-1,1h-1.028c-1.905,0-3.618-1.059-4.472-2.764l-6.109-12.219-7.503,14.443c-.179.343-.527.539-.889.539-.155,0-.312-.036-.46-.112-.49-.255-.681-.858-.427-1.349L10.285,6.806l-1.574-3.148c-.512-1.022-1.54-1.658-2.683-1.658h-1.028c-.553,0-1-.447-1-1s.447-1,1-1h1.028c1.905,0,3.618,1.059,4.472,2.764l8.789,17.578c.512,1.022,1.54,1.658,2.683,1.658h1.028c.553,0,1,.447,1,1Z"/>
            </SvgIcon>
        </StyledAction>
    );
}

export default StrongsIcon;