export class CurieService {
    static toFullUri(curie: string, includeHost: boolean): string {
        const [prefix, reference] = curie.split(":");
        const namespace = namespaceMappings[prefix];
        if (!namespace) {
            throw new Error(`Unknown CURIE prefix: ${prefix}`);
        }
        return `${includeHost ? "https://rtar.org" : ""}${namespace}${reference}`;
    }

    static toCurie(uri: string): string {
        for (const [prefix, namespace] of Object.entries(namespaceMappings)) {
            if (uri.startsWith(namespace)) {
                const reference = uri.substring(namespace.length);
                return `${prefix}:${reference}`;
            }
        }
        throw new Error(`No matching namespace for URI: ${uri}`);
    }
}

const namespaceMappings: { [prefix: string]: string } = {
    bible: "/reader/",
    post: "/posts/",
    search: "/search/",
    strongs: "/strongs/"
};

