import React from 'react';
import { Box, Typography } from "@mui/material";
import {useAppState} from "../../contexts/AppStateContext";

interface AppStateBoundaryProps {
    requiresInternet: boolean;
    requiresLogin: boolean;
    children: React.ReactNode;
}

const AppStateBoundary: React.FC<AppStateBoundaryProps> = ({ requiresInternet, requiresLogin, children }) => {
    const { isLoggedIn, isOnline } = useAppState();

    if (requiresInternet && !isOnline) {
        // Display message if internet is required but not connected
        return (
            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: "100%" }}>
                <Typography variant="h6">No Internet Connection</Typography>
                <Typography variant="body1">Please check your internet connection and try again.</Typography>
            </Box>
        );
    }

    if (requiresLogin && !isLoggedIn) {
        // Display message if login is required but user is not logged in
        return (
            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: "100%" }}>
                <Typography variant="h6">Unauthorized</Typography>
                <Typography variant="body1">You must be logged in to access this content.</Typography>
            </Box>
        );
    }

    // Render children if all conditions are met
    return <>{children}</>;
};

export default AppStateBoundary;