import ScriptureRef from "../model/ScriptureRef";
import BookInfo from "../model/Book";
import booksRaw from "../data/books.json";
import nkjvRaw from "../data/nkjv_bible.json";
import ScriptureContext from "../model/ScriptureContext";
import ScriptureAtom from "../model/Biblical/ScriptureAtom";
import {SemanticRef} from "../model/SemWeb/SemanticRef";

const {books} = nkjvRaw as Bible;
const bookInfos = booksRaw as BookInfo[];

class BibleService {
    private static books: BookInfo[];
    private static scriptures?: ScriptureAtom[];
    private static index: { [key: string]: ScriptureAtom } = {};

    static async getBooks(): Promise<BookInfo[]> {
        if (this.books === undefined) {
            this.books = bookInfos;
        }
        return this.books;
    }

    public static async getBookChapterCount(bookName: string): Promise<number> {
        bookName = bookName.toLowerCase();
        return (await this.getBooks()).find(b => b.name === bookName)?.chapters ?? 0;
    }

    public static async searchBookNames(query: string): Promise<SemanticRef[]> {
        const matches = await this.matchBooks(query);
        return matches.map(book => SemanticRef.fromBible(book.name));
    }

    public static async matchBooks(query: string): Promise<BookInfo[]> {
        const books = await this.getBooks();
        query = query.toLowerCase().replace(/\s+/g, '');
        return books.filter(book => {
            const startsWithName = query.startsWith(book.name) || book.name.startsWith(query);
            const startsWithAbbr = book.abbr.some(abbr => query.startsWith(abbr) || abbr.startsWith(query));
            return startsWithName || startsWithAbbr;
        });
    }

    public static async tryParse(str: string): Promise<SemanticRef | undefined> {
        if (str.startsWith("bible:"))
            return new SemanticRef(str, "");
        const regex = /(?<bookNo>\d{1,5}\s{0,3})?(?<bookName>[A-Za-z\s]{1,32})(\s{0,3}(?<chapter>\d+))?(:(?<from>\d{1,3}))?(-(?<to>\d{1,3}))?/;
        const result = str.match(regex);
        const toNumber = (x: string | undefined): number | undefined => {
            if (x === undefined) return undefined;
            const n = parseInt(x, 10);
            return isNaN(n) ? undefined : n;
        };
        if (!result || !result.groups) {
            return undefined;
        }
        const g = result.groups;
        let bookName = g?.bookName.trim().toLowerCase(); // the regex needs to support 'Song of Solomon'. The greedy operation then adds a space to the end of the book name.
        const chapter = toNumber(g?.chapter);
        if (bookName === undefined || chapter === undefined) {
            return undefined;
        }
        bookName = g?.bookNo === undefined ? bookName : `${g?.bookNo.trim()} ${bookName}`;
        const bookNameMatches = await this.matchBooks(bookName);
        if (bookNameMatches.length === 1) {
            return SemanticRef.fromBible(bookNameMatches[0].name, chapter, toNumber(g?.from), toNumber(g?.to));
        }
        return undefined;
    }

    public static async parseLabel(label: string): Promise<SemanticRef | undefined> {
        const ref = ScriptureRef.tryParse(label);
        if (ref) {
            return ref.toSemanticRef();
        }
        return undefined;
    }

    private static async buildBookRegex(): Promise<RegExp> {
        const books = await this.getBooks();
        const abbreviations = books.flatMap(book => book.abbr || []).map(abbr =>
            abbr.replace(/\s+/g, '\\s*') // Handle potential spaces within abbreviations
        );
        const abbrPattern = abbreviations.map(abbr => `${abbr}\\S{0,20}`).join('|');
        return new RegExp(`(?:${abbrPattern})`, 'gi'); // Case-insensitive global match
    }


    public static async parseScriptureReferences(text: string): Promise<SemanticRef[]> {
        const bookRegex = await this.buildBookRegex();
        const scriptureRegex = new RegExp(`(\\d*)\\s*(${bookRegex.source})\\s+(\\d+)(?::(\\d+))?(?:-(\\d+))?`, 'gi');
        const matches = [...text.matchAll(scriptureRegex)];

        return matches.map(match => {
            const [, bookNumber, bookName, chapter, fromVerse, toVerse] = match;
            const fullBookName = `${bookNumber ? bookNumber + ' ' : ''}${bookName}`.trim();
            return SemanticRef.fromBible(fullBookName, parseInt(chapter, 10), fromVerse ? parseInt(fromVerse, 10) : undefined, toVerse ? parseInt(toVerse, 10) : undefined);
        });
    }

    public static async getNextBook(bookName: string): Promise<BookInfo | undefined> {
        const books = await this.getBooks();
        const currentIndex = books.findIndex(b => b.name === bookName);
        if (currentIndex === -1) {
            throw new Error("Invalid book name");
        }
        if (currentIndex === books.length - 1) {
            return undefined;
        }
        return books[currentIndex + 1];
    }

    public static async getPreviousBook(bookName: string): Promise<BookInfo | undefined> {
        const books = await this.getBooks();
        const currentIndex = books.findIndex(b => b.name === bookName);
        if (currentIndex === -1) {
            throw new Error("Invalid book name");
        }
        if (currentIndex === 0) {
            return undefined;
        }
        return books[currentIndex - 1];
    }

    public static async nextChapter(current: ScriptureRef): Promise<ScriptureRef | undefined> {
        const chapters = await BibleService.getBookChapterCount(current.bookName);
        let bookName = current.bookName;
        let chapter = current.chapter;
        if (current.chapter === chapters) {
            const nextBook = (await BibleService.getNextBook(bookName))?.name;
            if (nextBook === undefined) {
                return undefined;
            } else {
                bookName = nextBook;
            }
            chapter = 1;
        } else {
            chapter++;
        }
        return new ScriptureRef(bookName, chapter, 1, 1024);
    }

    public static async previousChapter(current: ScriptureRef): Promise<ScriptureRef | undefined> {
        let bookName: string = current.bookName;
        let chapter: number = current.chapter;
        if (current.chapter === 1) {
            const nextBook = (await BibleService.getPreviousBook(bookName))?.name;
            if (nextBook === undefined) {
                return undefined;
            } else {
                bookName = nextBook;
            }
            chapter = await BibleService.getBookChapterCount(bookName);
        } else {
            chapter--;
        }
        return bookName === undefined ? undefined : new ScriptureRef(bookName, chapter, 1, 1024);
    }

    public static async nextBook(current: ScriptureRef): Promise<ScriptureRef | undefined> {
        const bookName = (await BibleService.getNextBook(current.bookName))?.name;
        return bookName === undefined ? undefined : new ScriptureRef(bookName, 1, 1, 1024);
    }

    public static async previousBook(current: ScriptureRef): Promise<ScriptureRef | undefined> {
        const bookName = (await BibleService.getPreviousBook(current.bookName))?.name;
        return bookName === undefined ? undefined : new ScriptureRef(bookName, 1, 1, 1024);
    }

    public static async expandContext(scripture: ScriptureAtom): Promise<ScriptureContext> {
        this.ensureScripturesAreLoaded();
        const ref = scripture.ref;
        let beforeRef: ScriptureRef | undefined;
        let afterRef: ScriptureRef | undefined;
        if (scripture.context !== undefined) {
            beforeRef = (ref.from === scripture.context.from) ? undefined : new ScriptureRef(ref.bookName, ref.chapter, scripture.context.from, ref.from - 1);
            afterRef = (ref.to === scripture.context.to) ? undefined : new ScriptureRef(ref.bookName, ref.chapter, ref.to + 1, scripture.context.to);
        } else {
            beforeRef = this.getContextBefore(ref);
            afterRef = this.getContextAfter(ref);
        }
        return new ScriptureContext(
            beforeRef === undefined ? undefined : await this.getMergedScripture(beforeRef),
            afterRef === undefined ? undefined : await this.getMergedScripture(afterRef));
    }

    private static getContextBefore(ref: ScriptureRef): ScriptureRef | undefined {
        let verse = ref.from;
        while (verse > 1 && BibleService.index[ref.toString(verse)].title === undefined) {
            verse--;
        }
        if (verse === ref.from) {
            return undefined;
        }
        return new ScriptureRef(ref.bookName, ref.chapter, verse, ref.from - 1);
    }

    private static getContextAfter(ref: ScriptureRef): ScriptureRef | undefined {
        let verse = ref.to;
        let scripture: ScriptureAtom | undefined = undefined;
        do {
            verse++;
            scripture = BibleService.index[ref.toString(verse)];
        } while ((scripture !== undefined) && (scripture.title === undefined));
        verse--;
        if (verse <= ref.to) {
            return undefined;
        }
        return new ScriptureRef(ref.bookName, ref.chapter, ref.to + 1, verse);
    }

    public static getAllScriptures(): ScriptureAtom[] {
        this.ensureScripturesAreLoaded();
        return this.scriptures ?? [];
    }

    public static async getMergedScripture(ref: ScriptureRef): Promise<ScriptureAtom> {
        if (ref === undefined) {
            throw new Error("A bible reference is required.")
        }
        const scriptures = await this.getScriptures(ref);
        if (scriptures.length === 0) {
            throw new Error(`Scripture reference, '${ref.toString()}' is invalid.`)
        }
        return ScriptureAtom.merge(scriptures);
    }

    public static async getScripturesFromEncoded(encodedRef: number): Promise<ScriptureAtom[]> {
        const ref = await ScriptureRef.FromEncodedRef(encodedRef);
        return BibleService.getScriptures(ref);
    }

    public static getScriptures(ref: ScriptureRef): Promise<ScriptureAtom[]> {
        this.ensureScripturesAreLoaded();
        let i = ref.from - 1;
        let endOfChapter = false;
        const results: ScriptureAtom[] = [];
        do {
            i++;
            const scripture = this.index[ref.toString(i)];
            if (scripture !== undefined) {
                results.push(scripture);
            } else {
                endOfChapter = true;
            }
        } while (i < ref.to && !endOfChapter);
        return new Promise(resolve => resolve(results));
    }

    private static ensureScripturesAreLoaded() {
        if (this.scriptures === undefined) {
            this.scriptures = books.flatMap(book => book.chapters
                .flatMap(chapter => chapter.verses
                    .flatMap(verse => {
                        const refStr = `${book.name} ${chapter.no}:${verse.no}`;
                        let text = verse.text.replace(/\([a-zA-Z]{1,2}\)|\[[a-z]{1,2}]/g, "");
                        text = `[${verse.no}] ${text}`;
                        const title = verse.title?.replace(/\([A-Z]{1,2}\)/g, "");
                        return new ScriptureAtom(null, null, refStr, text, [], undefined, title);
                    })));
            // Create an index for quick lookup
            for (const scripture of this.scriptures) {
                this.index[scripture.ref.toString()] = scripture;
            }
        }
    }

    static async getBookNameByIndex(number: number): Promise<string> {
        const books = await this.getBooks();
        const book = books[number - 1];
        if (book === undefined) {
            throw Error(`Book number ${number} is invalid.`);
        }
        return book.name;
    }
}

type Bible = {
    books: Book[];
}

type Book = {
    name: string;
    chapters: Chapter[];
}

type Chapter = {
    no: number;
    verses: Verse[];
}

type Verse = {
    no: number;
    text: string;
    title: string;
}

export default BibleService;