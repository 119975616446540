import styled from '@emotion/styled';
import AppBar from "@mui/material/AppBar";

const StyledAppBar = styled(AppBar)(
    ({ theme }) => ({
        padding: '0.2rem',
        marginBottom: '0.5rem',
        marginTop: 0,
        backgroundColor: '#343747',
    })
);

export default StyledAppBar;